import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

import { useAuth, useLanguage } from '../Utils/UseContext.jsx';
import { CustomButton } from '../components/Buttons.jsx';
import { HandleLogin } from '../Utils/ApiRequests.jsx';

//icon
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
//Translate
import WordTranslations from '../Main/Translations/WordTranslation.json'
import AuthTranslation from '../Main/Translations/Auth.json'

//style
import { StyledSubmitButton } from '../components/style/Buttons.styled.jsx';
import { AuthContainer, FormContainer, AuthContent } from '../components/style/Container.styled.jsx';
import { StyledCustomInput } from '../components/style/CustomInput.styled.jsx';
import '../components/style/Styles.css'

const ErrorMsg = (msg) => {
  const wrongCredsText = msg;
  //"Salasanan tai tunnus väärin.";
  return(
      <div>
          <p>{wrongCredsText}</p>
      </div>
  )
}

const Login = ({theme}) => {
    const navigate = useNavigate();
    // const {setToken} = AuthContext();
    const { state, dispatch } = useAuth();
    const {language} = useLanguage();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isWrong, setIsWrong] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [isVerified, setIsVerified] = useState(true);
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    return(
        // <div className='authContainer'>
        <AuthContainer>
          <AuthContent>
          <FormContainer>
          <h3>{WordTranslations.sign_in[lang]}</h3>
            <StyledCustomInput
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onFocus={() => setIsWrong(false)}

            />
            <div className='password-container'>
              <StyledCustomInput
                id="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onFocus={() => setIsWrong(false)}
                type={showPassword ? 'text' : 'password'}
              />
              <span className='show-password' onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? 
                              <IoMdEyeOff size={25} color={theme.colors.text}/>
                              :
                              <IoMdEye size={25} color={theme.colors.text}/>
                          }
              </span>
            </div>
            <CustomButton button={StyledSubmitButton} label={`${WordTranslations.sign_in[lang]}`} onClick={() => HandleLogin(email, password, navigate, dispatch, setIsWrong, setIsVerified)} style={{color: 'white'}}/>

          </FormContainer>

          {/* </div> */}
          {!isVerified && (ErrorMsg(`${AuthTranslation.verified_message[lang]}`))}
          {isWrong && (ErrorMsg(`${AuthTranslation.wrg_pwd_message[lang]}`))}
          <p style={{cursor: 'pointer'}} onClick={() => navigate('/forgot/password')}>{AuthTranslation.forgot_password[lang]}</p>
          <p>{AuthTranslation.have_account_message[lang]} <span style={{cursor: 'pointer'}} onClick={() => navigate('/registeration')}>{WordTranslations.sign_up[lang]}</span></p>
      </AuthContent>
      </AuthContainer>
    )
}

export default Login;