import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";

//components
import { ScrollView, MapPartName } from "../../../components/Components.jsx";

//Style
import { WorkoutContent, WorkoutRow} from "../StyledComponents.jsx";
//utils
import { Loading, isAccessRightValid} from "../../../Utils/Utils.jsx";
import { GetRole } from "../../../Utils/Role.jsx";
//Translations
import WordTranslations from '../../Translations/WordTranslation.json'
import NewWorkoutTranslations from '../../Translations/NewWorkout.json'
import WorkoutPartTranslations from '../../Translations/WorkoutPartList.json';


export const PersonalExerciseRow = ({data, selected, lang}) => {

  let mapMyWorkout;
  {data && (
  mapMyWorkout = Object.keys(data).map(id => {
    let accessRight = data[id].accessRight;
    let contentType = data[id].content_type;
    let name = Object.keys(data[id])[0];
    let part = MapPartName(data[id][name]);
    let expires = new Date(data[id].expiration_date);

    return (
      <WorkoutRow key={Math.random()} onClick={() => selected(id, name, accessRight)} columns={'1fr 0.3fr'}>
        <WorkoutContent key={Math.random()} align={'flex-start'} font={9}>
          <p key={Math.random()}>{isAccessRightValid(accessRight, 'owner') ? `${WordTranslations.my[lang]} ` : `${WordTranslations.public[lang]} `}
                                  {contentType === 'training_program' ? `${NewWorkoutTranslations.training_program[lang]}` : contentType}</p>
          <h4 key={Math.random()}>{name}</h4>
          {accessRight != 'owner' && (<p style={{fontSize: 10, opacity:'80%'}}>{WordTranslations.expires[lang]} {expires.getDate()}.{expires.getMonth() + 1}.{expires.getFullYear()}</p>)}
        </WorkoutContent>
        <WorkoutContent key={Math.random()} align={'flex-end'}>
          <ScrollView items={part} renderItem={(item) => <span>{item}</span>}/>
        </WorkoutContent>
      </WorkoutRow>
    )
  }
  )
)}

return (
    // <ExerciseContainer>
      <ScrollView items = {mapMyWorkout} renderItem={(item) => <span>{item}</span> } />
    // </ExerciseContainer>
)
}

const TrainingPlanRow = ({data, navToPlan, lang}) => {
  // Object.keys(data)
  let trainingPlan;
  {data?.trainingplan && (
    trainingPlan = data?.trainingplan.map(item => {
     let id = item.workout_id;
     let accessRight = item.accessright;
     let contentType = item.content_type;
     let name = item.workout_name
     let planContent = item.training_plan_content;
     let expires = new Date(item.expiry_date);

    return (
       <WorkoutRow key={Math.random()} onClick={() => navToPlan(id)} columns={'1fr 0.3fr'}>
         <WorkoutContent key={Math.random()} align={'flex-start'} font={9}>
           <p key={Math.random()}>{isAccessRightValid(accessRight, 'owner') ? `${WordTranslations.my[lang]} ` : `${WordTranslations.public[lang]} `}
                                   {contentType === 'training_plan' ? `${NewWorkoutTranslations.training_plan[lang]}` : contentType}</p>
           <h4 key={Math.random()}>{name}</h4>
           {accessRight != 'owner' && (<p style={{fontSize: 10, opacity:'80%'}}>{WordTranslations.expires[lang]}: {expires.getDate()}.{expires.getMonth() + 1}.{expires.getFullYear()}</p>)}
         </WorkoutContent>
         <WorkoutContent key={Math.random()} align={'flex-start'}>
           <p>{planContent.length} {WordTranslations.day.plural[lang]}</p>
         </WorkoutContent>
       </WorkoutRow>
    )
  }
  )
)}

return (
  <>
  {(trainingPlan.length > 0)? (
      <ScrollView items = {trainingPlan} renderItem={(item) => <span>{item}</span> } />
  ): <p style={{marginTop: 100}}>{WorkoutPartTranslations.empty[lang]}</p>
  }
  </>

)
}

const PersonalsVideoRow = ({data}) => {

  if(!data) return;

  const mapVideos = data.map((item) => {
    let accessRight = item?.accessright;
    let contentType = item?.content_type;
    let name = item?.workout_name;
    let expires = new Date(item?.expiration_date);

    return(
      <WorkoutRow key={Math.random()} onClick={() => console.log("CLICKED")} columns={'1fr 0.3fr'}>
        <WorkoutContent key={Math.random()} align={'flex-start'} font={9}>
          <p key={Math.random()}>{isAccessRightValid(accessRight, 'owner') ? 'My ' : 'public '}
                                  {contentType === 'video' ? 'video' : contentType}</p>
          <h4 key={Math.random()}>{name}</h4>
          {accessRight != 'owner' && (<p style={{fontSize: 10, opacity:'80%'}}>Expires: {expires.getDate()}.{expires.getMonth() + 1}.{expires.getFullYear()}</p>)}
        </WorkoutContent>
      </WorkoutRow>
    )
  })

  return(
      <ScrollView items = {mapVideos} renderItem={(item) => <span>{item}</span> } />
  )
}

const combineAndSortData = ({personalVideoData, personalData, trainingPlanData, sortKey, sortOrder = 'asc'}) => {
  // if ((!personalData || personalData?.length === 0) && (!personalVideoData || personalVideoData?.length === 0)) {
  //   return []; // Ei tarvitse lajitella, jos rivejä ei ole
  // }

  const trainingPlanItems = trainingPlanData ? trainingPlanData.map(item => ({
    id: item.workout_id,
    name: item.workout_name,
    type: 'training_plan',
    contentType: item.content_type,
    accessRight: item.accessright,
    expires: item.expiration_date,
    created_at: item?.created_at,
    exercises: item?.training_plan_content,
  })) : [];

  // Muokkaa videoiden dataa
  const videoItems = personalVideoData?.videos?.map(item => ({
    id: item.workout_id,
    name: item.workout_name,
    type: 'video',
    contentType: item.content_type,
    accessRight: item.accessright,
    expires: item.expiration_date,
    created_at: item?.created_at,
  })) || [];

  // Muokkaa treeniohjelmien dataa
  const workoutItems = personalData ? Object.keys(personalData).map(id => {
    const item = personalData[id];
    const name = Object.keys(item)[0];
    return {
      id,
      name,
      type: 'training_program',
      contentType: item.content_type,
      accessRight: item.accessRight,
      expires: item.expiration_date,
      part: MapPartName(item[name]),
      created_at: item?.created_at,
    };
  }) : [];

  // Yhdistä ja lajittele
  const combinedData = [...videoItems, ...workoutItems, ...trainingPlanItems];

  const sortedData = combinedData.sort((a, b) => {
    if (a[sortKey] < b[sortKey]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortKey] > b[sortKey]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  })

  return sortedData;
};

const AllData = ({personalData, personalVideoData, trainingPlanData, sortKey, sortOrder, selected, navToVideo, navToPlan, lang}) => {
  const combinedData = combineAndSortData({personalData, personalVideoData, trainingPlanData, sortKey, sortOrder});

  const handleSelected = (item) => {
    if(item?.type === 'training_program'){
      selected(item?.id, item?.name, item?.accessRight)
    }else if(item?.type === 'video'){
      navToVideo(item?.id);
    }else if(item?.type === 'training_plan'){
      navToPlan(item?.id);
    }
  }

  const itemType = (key) => {
    let type = '';
    switch (key) {
      case 'training_program':
        type = `${NewWorkoutTranslations.training_program[lang]}`;
        break;
      case 'training_plan':
        type = `${NewWorkoutTranslations.training_plan[lang]}`;
        break;
      case 'video':
        type = 'video';
        break;
    
      default:
        type = '';
        break;
    }

    return type;
  }

  return (
    <ScrollView
      items={combinedData}
      renderItem={(item) => (
        <WorkoutRow
          key={item.id}
          onClick={() => handleSelected(item)}
          columns={'1fr 0.3fr'}
        >
          <WorkoutContent align={'flex-start'} font={9}>
            <p>{isAccessRightValid(item.accessRight, 'owner') ? `${WordTranslations.my[lang]} ` : `${WordTranslations.my[lang]} `}
               {/* {item.type === 'video' ? 'video' : 'workout'} */}
                {itemType(item.type)}
               </p>
            <h4>{item.name}</h4>
            {item.accessRight !== 'owner' && item.expires && (
              <p style={{ fontSize: 10, opacity: '80%' }}>
                {WordTranslations.expires[lang]} {new Date(item.expires).toLocaleDateString()}
              </p>
            )}
          </WorkoutContent>
          {item.type === 'training_program' && (
            <WorkoutContent align={'flex-end'}>
              <ScrollView items={item.part} renderItem={(part) => <span>{part}</span>} />
            </WorkoutContent>
          )}
          {item.type === 'training_plan' && (
              <WorkoutContent key={Math.random()} align={'flex-start'}>
                 <p>{item?.exercises.length} {WordTranslations.day.plural[lang].toLowerCase()}</p>
              </WorkoutContent>
          )}
        </WorkoutRow>
      )}
    />
  );
};


const PersonalExercises = ({theme, personalData, setPersonalData, personalTrainingPlan, personalVideoData, activeData, lang}) => {
    const navigate = useNavigate();
    const [role, setRole] = useState(null)

    useEffect(() => {
      const userRole = async () => {
        const role = await GetRole();
        setRole(role)
      }
      userRole();
    },[])

    if(!personalData){
      const loading = personalData ? false : true;
      return (
        <div style={{marginTop: 50, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <ClipLoader
          color={theme.colors.button}
          loading={loading}
          // cssOverride={override}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <p>Loading...</p>
        </div>
      )
    } 
    // <Loading data={personalData} timeout={1000} />

      const selected = async (id, name, accessRight) => {  
         if(personalData[id][name] != undefined || personalData[id][name] != null){
          const data = {id: id, name: name, list:personalData[id][name]}
          navTo(data, accessRight, role)
         }else{
           console.error("SOMETHING WENT WRONG!")
         }
    }

    const navTo = (values, accessRight, role) => {
      const id = values.id;

      navigate(`/workout/my/part/${id}`, { state: {values, accessRight, role} });
    }

    const navToVideo = (id) => {

        if(id){
          // const key = `videoData-${data?.workout_id}`;
          // localStorage.setItem(key, JSON.stringify(data));
          navigate(`/workout/my/video/${id}`, {state:{id: id}})
        }else {
          console.error("No data found for id:", id);
      }
    }

    const navToPlan = (id) => {

      if(id){
        // const key = `videoData-${data?.workout_id}`;
        // localStorage.setItem(key, JSON.stringify(data));
        navigate(`/workout/my/plan/${id}`, {state:{id: id}})
      }else {
        console.error("No data found for id:", id);
    }
  }

    const section = (key) => {
      switch (key) {
        case 'video':
          return(
            <PersonalsVideoRow data={personalVideoData?.videos} navToVideo={navToVideo} lang={lang}/>
          )
        case 'training_program':
          return(
            <PersonalExerciseRow data = {personalData} setPersonalData = {setPersonalData} selected = {selected} role = {role} lang={lang}/>
          )
          case 'training_plan':
            return(
              <TrainingPlanRow data = {personalTrainingPlan} role = {role} navToPlan={navToPlan} lang={lang}/>
            )
      
        default:
          return(
            <AllData personalData={personalData} personalVideoData={personalVideoData} trainingPlanData={personalTrainingPlan?.trainingplan} sortKey={'created_at'} sortOrder={'desc'} selected={selected} navToVideo={navToVideo} navToPlan={navToPlan} lang={lang}/>

          )
      }
    }

    return (
        <>
        {section(activeData)}
        </>
    )
}

export default PersonalExercises;