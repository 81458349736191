import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
//utils
import { Loading, returnCurrency } from "../../../Utils/Utils.jsx";
import { GetEvaluationsData } from "../../../Utils/ApiRequests.jsx";
import { useAuth, useData, useLanguage } from "../../../Utils/UseContext.jsx";

//components
import { CountDownTimer } from "../../../components/Timer.jsx";
import { WorkoutMedia } from "../WorkoutMedia.jsx";
import { ShowStars } from "../../../components/Components.jsx";
//style
import { Row } from "../../../components/style/Row.styled.jsx";
import { ContentColumn } from "../../../components/style/Home.style.jsx";
import { HomeContentContainer } from "../../../components/style/Container.styled.jsx";
// icons
//translate
import WordTranslations from '../../../Main/Translations/WordTranslation.json'
import PublicTranslations from '../../../Main/Translations/Public.json'


const setDataToList = (data) => {

    let dataList = Object.keys(data)?.map(id => {
        let title = Object.keys(data[id])[0];
      
        let part = data[id][title].map((item, ) => {
          let partName = Object.values(item)[0][0].partofexercisename
          return <p key={Math.random().toString() + item} numberOfLines={1} ellipsizeMode="tail">{partName}</p>
        })
  
        return {id:id, title: title, expiredtime : data[id].expiredtime, price: data[id].price, owner: data[id].owner, partList: part, description: data[id].description}
      })

      return dataList;
}

const PublicContent = ({data, theme}) => {
    const navigate = useNavigate();
    const {state} = useAuth();
    const {language} = useLanguage();
    const isAuthenticated = state.isAuthenticated;
    const [list, setList] = useState([]);
    const [index, setIndex] = useState(0);
    const [timeThen, setTimeThen] = useState(moment().add(1000).format('mm:ss'));
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    useEffect(() => {
        if (data) {
          // setList(setDataToList(data));
          setList(data)
        //   setIsLoading(false);
        }

      }, [data]);
    
      useEffect(() => {
        const interval = setInterval(() => {
          if (CountDownTimer(timeThen) > 0) {
            setTimeThen(moment().add(10000).format('mm:ss'));
            setIndex(Math.floor(Math.random() * list.length)); // Arvotaan uusi indeksi
          } else {
            CountDownTimer(timeThen);
          }
        }, 1000);
        return () => clearInterval(interval); // Poistetaan intervalli, kun komponentti poistetaan
      }, [timeThen, list?.length]);
    
    
       if (list.length <= 0) {
         return <p>{PublicTranslations.noPublicWorkouts[lang]}</p>;
       }


      let starlist = [];
      if(list[index]?.evaluations){
        starlist = list[index]?.evaluations.map((evaluation) => evaluation?.stars);
      }

        return (
          <div onClick={() => isAuthenticated ? navigate(`/workout/public/${list[index].workout_id}`) : navigate('/workout/public') } style={{cursor: 'pointer'}}>
            <Row style={{cursor: 'pointer'}}>
              <h4 style={{marginBottom: 5}}>{list[index]?.workout_name}</h4>
            </Row>
            <Row style={{cursor: 'pointer'}}>
              <ContentColumn style={{cursor: 'pointer'}}>
                <WorkoutMedia src={list[index]?.media_url} type="video"/>
              </ContentColumn>
              <ContentColumn justify={'start'} margin={'0 0 0 21px'} style={{cursor: 'pointer'}}>
                <p style={{cursor: 'pointer'}}>{WordTranslations.price[lang]} {list[index].price} {returnCurrency(list[index]?.currency)}</p>  
                <p style={{cursor: 'pointer'}}>{WordTranslations.expires[lang]} {list[index]?.expired_time} {WordTranslations.day.ss[lang]}</p>
                <p style={{cursor: 'pointer'}}>{list[index]?.part_names?.length} {WordTranslations.exercise.singular[lang]} {list[index]?.part_names?.length === 1 ? WordTranslations.parts.singular[lang] : WordTranslations.parts.plural[lang]}</p>
              </ContentColumn>
            </Row>
            <Row style={{cursor: 'pointer'}}>
              <Row padding={'none'} style={{cursor: 'pointer'}}>
                  <p style={{fontSize: 10}}>{PublicTranslations.createdBy[lang]} {list[index].username}</p>
                  <ContentColumn style={{cursor: 'pointer'}}>
                      <Row style={{cursor: 'pointer'}}>
                          <ShowStars stars={starlist} theme={theme}/>
                      </Row>
                  </ContentColumn>
              </Row>  
            </Row>
          </div>
        )
}

const PublicWorkoutSection = ({theme}) => {
    const {publicData, setPublicData} = useData();
    const [starList, setStarList] = useState([]);
    const [evaluationsData, setEvaluationsData] = useState();

    return (
      <HomeContentContainer>
          <Loading data={publicData?.data} timeout={3000} theme={theme}>
            <PublicContent data = {publicData?.data} theme={theme}/>
          </Loading>
      </HomeContentContainer>
    
    )
}

export default PublicWorkoutSection;