import styled from 'styled-components';

export const StyledExitButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    // ${({theme}) => theme.colors.body || '#fff'};
    color: ${({theme}) => theme.colors.text || '#000'};
    border: none;
    padding: 5px;
    height: 2em;
    width: 2em;
    font-size: 16px;

    &:hover{
        // background: rgb(242, 242, 242, 0.3);
        color: ${({theme}) => theme.colors.warning || '#000'};
        
    }
`;

export const StyledTimerButton = styled.button`
display:flex;
width: 55px;
height: 55px;
border-radius: 50%;
border: none;
background-color: transparent;
justify-content: center;
align-items: center;
// box-shadow: 1px 1.5px ${({theme}) => theme.colors.shadow};
// padding: 10px;


&:hover{
    background:rgb(23, 54, 52, 0.6);
    box-shadow: none;
}
`

export const StyledPublishButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({theme}) => theme.colors.body || '#fff'};
    color: ${({theme}) => theme.colors.text || '#000'};
    border: none;
    padding: 5px;
    border-radius: 1em;
    pointer: cursor;

    &:hover{
        background: ${({disabled}) => !disabled && ('rgb(242, 242, 242, 0.3)')};
        
    }
`;

export const StyledAddButton = styled.button`
display:flex;
width: ${({width}) => width || '55px'};
height: ${({height}) => height || '55px'};
border-radius: 50%;
border: none;
background-color: transparent;
justify-content: center;
align-items: center;
color: ${({theme}) => theme.colors.text};
cursor: pointer;

&:hover{
    background:rgb(23, 54, 52, 0.8);
    box-shadow: none;
}

@media screen and (min-width: 481px) and (min-height: 500px) {
    // bottom: 60px;

  }

`

export const StyledAnalyticsButton = styled.button`
display:flex;
width: 55px;
height: 55px;
border-radius: 50%;
border: none;
background-color: transparent;
justify-content: center;
align-items: center;
color: ${({theme}) => theme.colors.text};
cursor: pointer;

&:hover{
    background:rgb(23, 54, 52, 0.8);
    box-shadow: none;
}

@media screen and (min-width: 481px) and (min-height: 500px) {
    // bottom: 60px;

  }

`

export const StyledSubmitButton = styled.button`
// height: 25px;
border-radius: 10px;
border-color: ${(props) => props.disabled && ('rgb(23, 54, 52)')};
justify-content: center;
align-items: center;
padding: 6px;
color: ${({theme, disabled}) => disabled ?  'grey' : 'white'};
cursor: ${(props) => props.disabled ? 'none' : 'pointer'};
background: rgb(38, 82, 124, 0.9);
width: ${({width}) => width || '100%'};

&:hover{
    background: ${(props) => props.disabled ? 'rgb(38, 82, 124, 0.9)' : 'rgb(38, 82, 124, 0.6)' };
}
`

export const StyledHalfRoundLeft = styled.button`
border-top-left-radius: 50px;
border-bottom-left-radius: 50px;
border-color: ${(props) => props.disabled && ('rgb(23, 54, 52)')};
height: 60px;
width: 30px;
// background-color: ${({theme}) => theme.colors.button};
background:rgb(23, 54, 52, 0.6);
color: ${({theme, disabled}) => disabled ?  !theme.colors.text : theme.colors.text};
margin: 2px;
cursor: ${(props) => props.disabled ? 'none' : 'pointer'};

&:hover{
    background: ${(props) => props.disabled ? 'rgb(23, 54, 52, 0.6)' : 'rgb(23, 54, 52, 0.2)' };
}
`

export const StyledHalfRoundRight = styled.button`
border-top-right-radius: 50px;
border-bottom-right-radius: 50px;
border-color: ${(props) => props.disabled && ('rgb(23, 54, 52)')};
height: 60px;
width: 30px;
background-color: ${({theme}) => theme.colors.button};
// background:rgb(23, 54, 52, 0.6);
color: ${({theme, disabled}) => disabled ?  !theme.colors.text : theme.colors.text};
margin: 2px;
cursor: ${(props) => props.disabled ? 'none' : 'pointer'};

&:hover{
    background: ${(props) => props.disabled ? 'rgb(23, 54, 52, 0.6)' : 'rgb(23, 54, 52, 0.2)' };
}
`

export const StyledLoginButton = styled.button`
    width: 10em; 
    border-radius: 30px; 
    padding:10px;
    background: ${({theme}) => theme.colors.button};
    color: ${({theme}) => theme.colors.text}
`

export const StyledLogoutButton = styled.button`
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 2;
`

export const StyledBorderSquareButton = styled.button`
    border-color: ${(props) => props.disabled && ('rgb(23, 54, 52)')};
    background-color: ${({theme}) => theme.colors.textBg};
    padding: 2px;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    color: ${({theme, disabled}) => disabled ?  'grey' : theme.colors.text};
    cursor: ${(props) => props.disabled ? 'none' : 'pointer'};

    &:hover{
        background: ${(props) => props.disabled ? 'rgb(38, 82, 124, 0.9)' : 'rgb(38, 82, 124, 0.6)' };
    }
`

export const Styled20radiusButton = styled.button`
height: 32px;
border-radius: 20px;
border-color: ${(props) => props.disabled && ('rgb(23, 54, 52)')};
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
padding: 6px 12px 6px 12px;
// margin: ${(props) => props.margin || '10px'};
color: ${({theme}) => theme.colors.text};
cursor: ${(props) => props.disabled ? 'none' : 'pointer'};
background: ${(props) => props.bg || 'rgb(38, 82, 124, 0.9)'};
width:fit-content;

&:hover{
    background: ${(props) => props.disabled ? 'rgb(38, 82, 124, 0.9)' : 'rgb(38, 82, 124, 0.6)' };
}
`

export const TransparentButton = styled.button`
    height: ${({height}) => height || '32px'};
    width: ${({width}) => width || 'fit-content'};
    border-radius: ${({borderRadius}) => borderRadius || '10px'};
    border-color: ${(props) => props.disabled && ('rgb(23, 54, 52)')};
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 6px 12px 6px 12px;
    margin: ${(props) => props.margin || '0px 10px 0px 10px'};
    color: ${({isactive, theme}) => !isactive ? theme.colors.disabledText : theme.colors.text };
    cursor: ${({isactive}) => isactive &&('pointer')};
    background: ${(props) => props.bg || 'transparent'};
    font-weight: ${({isactive}) => isactive && ('700')};


    &:hover {
        background: ${({theme, isactive}) => !isactive && (theme.colors.btn_hover)};
        color: ${({theme}) => theme.colors.text}
    }

    @media screen and (min-width: 600px) { // desktop
        font-size: 20px;        
    }

`




