import React, {useState, useEffect} from "react";
import { Navigate, useLocation, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
//Utils
import { useAuth, useData, useWebSocket, useLanguage } from "../../../Utils/UseContext.jsx";
import { Loading, DateNow, DateToString, AddTokenToHeaders} from "../../../Utils/Utils.jsx";
//API
//COmponents
import { GetPublicData } from "../../../Utils/ApiRequests.jsx";
import { PublicExerciseSection } from "./PublicExerciseSection.jsx";
import { WorkoutNav } from "../../../components/Nav.jsx";
//icon
//Style
import { WorkoutRow, WorkoutContent, WorkoutContainer, ExerciseContainer} from '../StyledComponents.jsx'


const PublicWorkout = ({theme}) => {
    const {language} = useLanguage();
    const {publicData, setPublicData} = useData();

    const ws = useWebSocket();

    useEffect(() => {
      if (ws) {
        GetPublicData({setPublicData, ws});
      }
    }, [ws]);

    return (
        <WorkoutContainer>
          {/* <h2>{'Public workout'}</h2> */}
          <WorkoutNav />
          <Loading data={publicData?.data} timeout={1000} theme={theme}>
            <PublicExerciseSection data = {publicData?.data} theme={theme}/>
          </Loading>
        </WorkoutContainer>
    )
}

export default PublicWorkout;