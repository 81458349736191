import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ConnectionContext } from '../Utils/UseContext';
import './style/connectionIndicatorStyle.css'

const ConnectionIndicator = ({children}) => {
    const { isConnected } = useContext(ConnectionContext);
    const location = useLocation();
  
    // Älä näytä latausikonia pääsivulla (Main)
    if (location.pathname === '/') {
      return children; // Palaa normaalin sisällön tilaan
    }
  
    return (
        <div className="connection-indicator-wrapper">
      {!isConnected && (
        <div className="overlay">
          {/* <div className="loader">Loading...</div> */}
            <div className='spinner'></div>

            <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
                <span className="letter">C</span>
                <span className="letter">o</span>
                <span className="letter">n</span>
                <span className="letter">n</span>
                <span className="letter">e</span>
                <span className="letter">c</span>
                <span className="letter">t</span>
                <span className="letter">i</span>
                <span className="letter">n</span>
                <span className="letter">g</span>
                <span className="letter">&nbsp;</span>
                <span className="letter">t</span>
                <span className="letter">o</span>
                <span className="letter">&nbsp;</span>
                <span className="letter">t</span>
                <span className="letter">h</span>
                <span className="letter">e</span>
                <span className="letter">&nbsp;</span>
                <span className="letter">s</span>
                <span className="letter">e</span>
                <span className="letter">r</span>
                <span className="letter">v</span>
                <span className="letter">e</span>
                <span className="letter">r</span>
                <span className="letter">.</span>
                <span className="letter">.</span>
                <span className="letter">.</span>
            </div>
                {/* <div class="loader">
                    <div class="scanner">
                        <span>Connectin to the server...</span>
                    </div>
                </div> */}
        </div>
      )}
      <div className={isConnected ? '' : 'blur'}>
        {children}
      </div>
    </div>
  );
};

export default ConnectionIndicator;
