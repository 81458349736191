import React, {useEffect, useState} from "react";
import { useLanguage } from "../Utils/UseContext";
import finFlag from "../components/style/Icons/finland_flag.png";
import {usFlag} from "../components/style/Icons/us_flag.png";

const LanguageChanger = () => {
    const { language, setLanguage } = useLanguage();
    const [openDropdown, setOpenDropdown] = useState(false);

    const [languages, setLanguages] = useState([
        { value: 'fi-FI', icon: finFlag, label: `Suomi`},
        { value: 'en-EN', icon: 'flag', label: `English` },
    ]);


    const dropdown = (languages) => {
        return(
            <select style={{background: 'transparent', border: 'none', height: '3em'}} value={language} onChange={handleChange} onClick={() => setOpenDropdown(!openDropdown)}>
                {languages.map((language, index) => (
                  <option  key={index} value={language.value}>
                    {language.label}
                  </option>
                ))}
            </select>
        )
    }

    const handleChange = async (e) => {
        const value = e.target.value;
        setLanguage(value);
  };

    return(
        <>
            {dropdown(languages)}
        </>
    )

}

export default LanguageChanger;