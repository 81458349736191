import React, {useEffect, useState, useRef} from "react";
import { useNavigate } from 'react-router-dom';

//API
import { Notify } from "../Utils/ApiRequests.jsx";

// components
import PublicWorkoutSection from "./Workout/Public/PublicWorkoutSection.jsx";
import WorkoutDetails from "./Workout/WorkoutDetails.jsx";
import Footer from "../components/Footer.jsx";
import HeaderText from "./HeaderText.jsx";
import WeekExercise from "./Calendar/WeekExercise.jsx";
//Style
import { HomeContentContainer, MainContentContainer, TextContentContainer, TextContainer, ColumnContainer, FadeInContainer } from "../components/style/Container.styled.jsx";
import { Row, Column } from "../components/style/Row.styled.jsx";
import { MainContainer, MainSection, TopSquare} from "../components/style/Section.styled.jsx";
import { StyledHomeCard } from "../components/style/Container.styled.jsx";
//image
import gym from '../components/style/images/gym.jpg'

//utils
import { GetPublicData } from "../Utils/ApiRequests.jsx";
import { useData, useLanguage, useWebSocket } from "../Utils/UseContext.jsx";
//Translations
import HomeTranslations from './Translations/Home.json'


export const ContentContainer = ({title, content, width, justify, align, bg, titleColor, nav, Style}) => {

    return(
         <MainContentContainer bg={bg} width={width} justify={justify} align={align}>
            <Column>
                <Row padding={'none'} color={titleColor} onClick={nav}>
                    <h3 style={Style}>{title}</h3>
                </Row>
                {content}
             </Column>
        </MainContentContainer>
    )
}


const Main = ({onInstallClick, theme}) => {
    const navigate = useNavigate();
    const {setPublicData} = useData();
    const {language} = useLanguage();
    const [tips, setTips] = useState("");
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = HomeTranslations?.supported[lang] ? HomeTranslations?.supported[lang] : HomeTranslations?.supported['en-EN']

    const ws = useWebSocket();

    useEffect(() => {
      if (ws) {
        GetPublicData({setPublicData, ws});
      }
    }, [ws]);
    
    // Content to this should come frome DB, now it is hard coded
    const newsContent = (news) => {
        return(
            <HomeContentContainer>
                <TextContentContainer>
                    {news}
                </TextContentContainer>
            </HomeContentContainer>
        )
    }

    return(
        <>
            <MainSection >
                <Row margin={'30px auto'} justify_mobile={'center'} margin_mobile={'30px auto'}>
                    <HeaderText theme={theme} language={language}/>
                </Row>
                <TopSquare image={gym}/>
            </MainSection>
            <MainContainer>
                {/* <ContentContainer title={HomeTranslations.news[lang]} content={newsContent('New feature released, now you can also create training plans.')} bg={'transparent'}/> */}
                {/* <WorkoutDetails theme={theme} language={language}/> */}
                <StyledHomeCard theme={theme} border={'none'} justify={'space-between'}> 
                    <ContentContainer title={HomeTranslations.news[lang]} content={newsContent('New feature released, now you can also create training plans.')} bg={'transparent'}/>
                    {tips.length > 0 && (<ContentContainer title={'TIPS'} content={newsContent(tips)} bg={'transparent'}/>)}
                    <ContentContainer title={HomeTranslations.public[lang]} content={<PublicWorkoutSection theme={theme}/>} nav={() => navigate('/workout/public')} bg={'transparent'} Style={{cursor:'pointer'}}/>

                </StyledHomeCard>
                {/* <StyledHomeCard theme={theme}>
                </StyledHomeCard> */}
                
                <StyledHomeCard theme={theme}>
                    <WeekExercise lang={lang}/>
                </StyledHomeCard>
                
                {/* <ContentContainer title={HomeTranslations.public[lang]} content={<PublicWorkoutSection theme={theme}/>} nav={() => navigate('/workout/public')} bg={'transparent'} Style={{cursor:'pointer'}}/> */}
            </MainContainer>
            <Footer language={language} onInstallClick={onInstallClick}/>
        </>
    )
}

export default Main