import styled from "styled-components";

export const MainContainer = styled.div`    
  height: 100vh; /* Full height of viewport */
  width: 100vw; /* Make sure it doesn't exceed viewport width */
  max-width: 100%; /* Ensures it doesn't overflow horizontally */
  padding: 20px;

display: grid;
grid-template-columns: 1fr 1fr; /* Kaksi saraketta */
grid-template-rows: auto auto; /* Kaksi riviä */
grid-template-areas: 
  "left right";
  // "left-bottom right";
gap: 20px; /* Lisää väli riveihin ja sarakkeisiin */

& > :first-child {
  grid-area: left; /* Vasemmalla ylhäällä */
}

// & > :nth-child(2) {
//   grid-area: left-bottom; /* Vasemmalla alhaalla */
// }

& > :nth-child(2) {
  grid-area: right; /* Oikealla */
}

/* Media query mobiilille */
@media screen and (max-width: 768px) {
    height: 100%;
    padding: 0px;
    grid-template-columns: 1fr; /* Yksi sarake mobiilissa */
    grid-template-rows: auto auto auto; /* Kolme riviä */
    grid-template-areas: 
      "right"
      "left";
      // "left-bottom";
  }

`;

export const MainSection = styled.section`
    display: flex;
    flex-direction: column;
    // align-items: center;
    // overflow:auto;

    @media screen and (min-width: 600px) { // desktop
        // position: relative;
        // height: 100vh;
        // scroll-snap-align: end;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        //align-items: ${(props) => props.align || 'center'};
        // z-index:-2;
    }
`;

export const TopTriangle = styled.div`
@media screen and (min-width: 600px) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 30%, 0 100%);
  filter: blur(0.5px);
  z-index:-1;
  opacity: 0.4;
}
`;

export const TopSquare = styled.div`
@media screen and (max-width: 600px) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  z-index:-1;
  opacity: 0.6;
}
`;