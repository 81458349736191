import React, {useState, useEffect} from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth, useData, useWebSocket, useLanguage } from "../../../Utils/UseContext";
import { GetPublicData, ConfirmAccessRight } from "../../../Utils/ApiRequests";
import { ExpiryDate, ScrollView, CustomPopUp } from "../../../components/Components";
import { StyledSubmitButton, TransparentButton } from "../../../components/style/Buttons.styled";
import { DescriptionContainer, Container, PublishDetailsContainer, ButtonContainer, Container100, ScrollAuto } from "../../../components/style/Container.styled";
import { Row, Column } from "../../../components/style/Row.styled";
import { WorkoutMedia } from "../StyledComponents";
import { DateNow, IncreaseDays } from "../../../Utils/Utils";
import { ShowStars } from "../../../components/Components";
import { ConfirmPayment } from "../../Stripe/StripeAPI";
// icon
import { MdKeyboardArrowDown, MdKeyboardArrowLeft } from "react-icons/md";
//Translate
import WordTranslations from '../../../Main/Translations/WordTranslation.json'
import PublicTranslations from '../../../Main/Translations/Public.json'


const PreviewScreen = ({theme, isAuthenticated}) => {
    const {state} = useAuth()
    const {language} = useLanguage();
    const navigate = useNavigate();
    const location = useLocation(); 
    const { id } = useParams();
    const {publicData, setPublicData} = useData(null);
    const [selectedItem, setSelectedItem] = useState(null); // Tila valitulle itemille
    const [isVisible, setIsVisible] = useState(false);
    const [showPartNames, setShowPartNames] = useState(true)
    const [showDescription, setShowDescription] = useState(false);
    const [popupContent, setPopupContent] = useState("");
    const ws = useWebSocket();
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const canceled = queryParams.get('canceled');

      if(canceled){
        toast.error("OOPS! Event canceled.");
        navigate(location.pathname, { replace: true });
      }

    },[location.search])


    useEffect(() => {
      if(ws){
        GetPublicData({setPublicData, ws});     
      }
    },[ws])

    useEffect(()=> {
        if (publicData.data && id) {
            const foundItem = publicData.data.find(item => item.workout_id === id); // Suodata data listasta id:n perusteella
            setSelectedItem(foundItem); // Aseta löydetty item tilaansa
          }
    },[publicData?.data, id])




    // const message = `Haluatko ostaa harjoitusohjelman: ${title}? \nHinta: ${price} \nAlkaen:${DateToString(DateNow())}  \nPäättyen:${DateToString(ExpiryDate(Number(expiredIn)))}`
  
    const handleCancel = () => {
      setIsVisible(false)
      // closeModal()
    }
  
    const handleConfirm = async () => {
      // tämän pitäisi suorittaa maksu operaatio ja kun operaatio suoritettu annetaan oikeus katsella harjoitusta.
      await ConfirmAccessRight(id, ExpiryDate(Number(selectedItem?.expired_time)))
      handleCancel()
    }

    const handleSubmit = async (event) => {
      event.preventDefault();

        const priceId = selectedItem?.stripe_price_id

        const bodyData = {
          priceId: priceId, // Hinta-id, joka lähetetään palvelimelle
          workout_id: id,
          expiryDate: ExpiryDate(Number(selectedItem?.expired_time))
        };
      

        await ConfirmPayment(bodyData);
    };

    const returnCurrency = (currency) => {
      switch (currency) {
          case 'eur':
              return '€';
          case 'usd':
              return '$'
          default:
              return '€';
      }
    }

    const ConfirmationPopUp = () => {
     const buttons = [
       { label: 'No', onClick: handleCancel , bstyle: StyledSubmitButton},
       { label: 'Yes', onClick: handleConfirm, bstyle: StyledSubmitButton}
     ];
      const info = selectedItem?.type === 'training_plan' ? `${PublicTranslations.confirm_buy_message_info[lang].split(':')[0]} ${selectedItem?.meta_data?.workoutDays + selectedItem?.meta_data.restDays} ${PublicTranslations.confirm_buy_message_info[lang].split(':')[1]} ${PublicTranslations.confirm_buy_message_info[lang].split(':')[2]}` : "";
      const message =`${info} ${PublicTranslations.confirm_buy_message[lang].split(':')[0]} ${selectedItem?.workout_name} ${PublicTranslations.confirm_buy_message[lang].split(':')[1]} ${selectedItem?.price}${returnCurrency(selectedItem?.currency)}`
      //`Are you sure you want to buy ${selectedItem?.workout_name} for ${selectedItem?.price}${returnCurrency(selectedItem?.currency)}?` 
        setPopupContent({message: message, buttons: buttons});
        setIsVisible(!isVisible);
    }
    
    let starlist = [];
    if(selectedItem?.evaluations){
      starlist = selectedItem?.evaluations.map((evaluation) => evaluation?.stars);
    }
    
    const getTypeName = (key) => {
        switch (key) {
          case "training_plan":
            return "Training plan"
          case "training program":
            return "Training program"
          default:
            return ""
        }
        
    }

    return (
       <Container direction={'column'}>
        <Container100>
            <Row padding={'0px 0px 10px 0px'} style={{background: theme.colors.body}} borderBottom={`1px solid`}>
              <WorkoutMedia src={selectedItem?.media_url} type="video" minHeight={'80px'} minWidth={'80px'}/>
              <Column align={'end'} justify={'center'} padding={'0px 0px 10px 20px'}>
              <ButtonContainer padding={'0px 0px 10px 0px'}>
            {state?.isAuthenticated && (
              <>
                 {selectedItem?.price === 0 ? 
                 <StyledSubmitButton onClick={() => ConfirmationPopUp()} width={'100px'}>
                   {WordTranslations.buy[lang]} {selectedItem?.price}{returnCurrency(selectedItem?.currency)}
                 </StyledSubmitButton>
                 :
                 <form onClick={handleSubmit}>
                    <StyledSubmitButton type="submit" width={'100px'}>{WordTranslations.buy[lang]} {selectedItem?.price}{returnCurrency(selectedItem?.currency)}</StyledSubmitButton>
                </form>
                }
                <TransparentButton onClick={() => navigate('/workout/public')} isactive={true} style={{marginLeft: 20}}>
                   X
                </TransparentButton>
              </>
            )}
           </ButtonContainer>
                <p style={{fontSize: 10}}>{getTypeName(selectedItem?.type)}</p>
                <h4>{selectedItem?.workout_name}</h4>
              </Column>
            </Row>               
            {selectedItem?.type === 'training_plan' && (
              <Row padding={'0px 0px 10px 0px'} borderBottom={`1px solid`}>
                <p>{selectedItem?.meta_data?.workoutDays} {WordTranslations.exercise.singular[lang]} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                <p>{selectedItem?.meta_data?.restDays} {WordTranslations.rest[lang]} {WordTranslations.day.plural[lang].toLowerCase()}</p>
                <p>{WordTranslations.duration[lang]} {selectedItem?.meta_data?.workoutDays + selectedItem?.meta_data?.restDays} {WordTranslations.day.plural[lang].toLowerCase()}</p>
              </Row>
              )}
            <Row padding={'0px 0px 10px 0px'} borderBottom={`1px solid`} align={'start'}>
              <Column width={'60%'} >
                <Row padding={'0px'} maxwidth={'150px'}>
                  <ShowStars stars={starlist} theme={theme}/>
                </Row>
                <p style={{fontSize: 10}}>{PublicTranslations.review.part1[lang]} {starlist.length} {PublicTranslations.review.part2[lang]}</p>
              </Column>
                {/* <h5 onClick={() => console.log("POP UP TO COMMENTS")}>Comments: {selectedItem?.evaluations?.length}</h5> */}
            </Row>
            
            <Column height={'fit-content'}>
            <Row padding={'0px'} onClick={() => setShowDescription(!showDescription)}>
              <h3>{WordTranslations.description[lang]}</h3>
              {showDescription ? <MdKeyboardArrowLeft size={25} /> : <MdKeyboardArrowDown size={25} />}
            </Row>
              <Row align={'flex-start'} padding={'10px 0px 10px 0px'} style={{background: theme.colors.body}} borderBottom={`1px solid`}>
                <Row align={'flex-start'} padding={'0px'} width={'100%'} height={!showDescription ? '40px' : '100%'} style={{overflow:'hidden'}}>
                  {selectedItem?.description}
                </Row>
              </Row>
            </Column>
            <Column width={'100%'} height={'fit-content'} margin={'10px auto'} borderBottom={`1px solid`} padding={'0px 0px 10px 0px'} >
              <Row margin={'0px 0px 5px 0px'} padding={'0'} justify={'flex-start'} onClick={() => setShowPartNames(!showPartNames)}>
                 <h5 style={{marginRight: 5}}>{PublicTranslations.includes.part1[lang]} {selectedItem?.part_names?.length} {PublicTranslations.includes.part2[lang]}</h5>
                 {showPartNames ? <MdKeyboardArrowLeft size={25} /> : <MdKeyboardArrowDown size={25} />}
               </Row>
               <div style={{height: showPartNames ? 'auto' : 0}}>
                 {(selectedItem?.display_parts && selectedItem?.part_names?.length > 0)&& (
                   <ScrollView items={selectedItem?.part_names} renderItem={(item) => <p style={{textTransform: 'uppercase'}}>{item}</p>}/>
                   )
                 }
               </div>
            </Column>

           {/* <PublishDetailsContainer> */}
             <Row padding={'0px 0px 10px 0px'} margin={'10px 0 0 0'} borderBottom={`1px solid`}>
                <Column>
                  <h5 style={{marginBottom: 5}}>{PublicTranslations.validity[lang]}</h5>
                  <p>{DateNow().toLocaleDateString()} - {IncreaseDays(selectedItem?.expired_time)}</p>
                  <p>{PublicTranslations.canView.part1[lang]} {selectedItem?.expired_time} {PublicTranslations.canView.part2[lang]}</p>
                </Column>
                <Column justify={'flex-end'}>
                  <h5 style={{marginBottom: 5}}>{WordTranslations.price[lang]}</h5>
                  <span style={{marginRight: 10}}>
                    {selectedItem?.price === 0 ? 
                    <h3>FREE</h3> 
                    : 
                    <h3>{selectedItem?.price} {returnCurrency(selectedItem?.currency)}</h3>
                    }
                  </span>
                </Column>
             </Row>
           {/* </PublishDetailsContainer> */}

           <CustomPopUp content={popupContent} isOpen ={isVisible} setIsOpen={() => setIsVisible(!isVisible)} div={DescriptionContainer} />
           </Container100>
       </Container>
    )
  }

  export default PreviewScreen;