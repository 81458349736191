import React, {useState, useEffect, useCallback} from "react";
import Modal from 'react-modal';
import { useLocation, useNavigate} from 'react-router-dom';
import { CustomTextInput } from "../../../components/Components.jsx";
// style
import { ExerciseContainer, ExerciseContent, WorkoutRow, WorkoutContent, WorkoutDetailsRow, Title} from "../StyledComponents.jsx";
import { Row } from "../../../components/style/Row.styled.jsx";
import { ExerciseDetailsInput } from "../../../components/style/CustomInput.styled.jsx";
import { DescriptionContainer, BottomFloatingContainer, RightBottomFloatingContainer } from "../../../components/style/Container.styled.jsx";
//API
import { UpdateExerciseDetails, UpdateUserExerciseOrder} from "../../../Utils/ApiRequests.jsx";
import Timer from '../../../components/Timer.jsx'
import { CustomPopUp, CustomCheckBox } from "../../../components/Components.jsx";
import { SetToLocalStorage, getLast } from "../../../Utils/Utils.jsx";
import { Reorder } from "framer-motion"
import { WorkoutMedia } from "../WorkoutMedia.jsx";

// icon
import { IoSwapVerticalSharp, IoCloseOutline, IoInformationCircleOutline } from "react-icons/io5";
import { useAuth, useLanguage } from "../../../Utils/UseContext.jsx";
//Translations
import WordTranslations from '../../Translations/WordTranslation.json'
import NewWorkoutTranslations from '../../Translations/NewWorkout.json'




Modal.setAppElement('#root');

const ClearCheckbox = (data) => {
  const clearCheckbox = data.map((item) => {
    return item.isCompleted = false;
  })

}

const AddIsComplete = (data) => {
  const updateItems = data.map((item) => {
    return {...item, isCompleted: false}
  })

  return updateItems;
}

const CountCompletionRate = (data) => {
  let rate;
  const orginalLength = data.length;
  const completedArray = [];
  data.map((item) => {
    if(item.isCompleted){
      const completedObj = {
        "id":item.ExerciseId,
        "name":item.name_of_exercise,
        "description":item.description,
        "weight":item.weight,
        "amount":[item.times] + 'x' + [item.amount]
      }
      completedArray.push(completedObj)
    }
  })
  
  rate = (completedArray.length/orginalLength)* 100;

  return {completed: completedArray, rate: rate};
}

const CheckExerciseId = (id) => {
  const parsed = JSON.parse(localStorage.getItem('currentlyRun'))
  const isSame = id === parsed.id;
  
  return isSame;
}

// export const FillIsComplete = (workoutLength, setExerciseCompleted) => {
//   const fillCompleteList = Array(workoutLength).fill(false);
//   setExerciseCompleted(fillCompleteList)
// }

export const DraggableList = ({ items, id, handleTaskToggle, setExercises, exercises, isTimer, lang}) => {
  const {dispatch} = useAuth();
  const navigate = useNavigate();
  const [isDraggable, setIsDraggable] = useState(false);
  let Wrapper = isDraggable ? Reorder.Group : 'div';
  const part_id = id;

  const handleOnBlur = async (name, value, id, index) => {
    if(value){
      setExercises((prevState) => {
      const newData = [...prevState]
       if(newData[index] !== undefined){
         switch (name) {
           case "weight":
             newData[index].weight = value;
             break;
           case "amount":
             newData[index].amount = value;
             break;
           case "times":
             newData[index].times = value;
             break;
           default:
             console.error("Something went wrong!")
             break;
         }
       }
      return newData;
     })
      await UpdateExerciseDetails(id, value, name, part_id)

    }else{
      console.error(" KEY IS NOT VALID")
    }
  }

  const saveNewOrder = async (value) => {
    sessionStorage.setItem('order', JSON.stringify(value));
    await UpdateUserExerciseOrder(value);
  }

  return (
    <>
      <Wrapper axis="y" onReorder={setExercises} values={items} style={{listStyle:'none', paddingLeft:0}} >
          {items.map((value, index) => {
            return <ExercriseRow key={value.ExerciseId}
                      isDraggable={isDraggable} 
                      value={value} 
                      partId={id} 
                      index = {index} 
                      id={value.ExerciseId} 
                      name={value.name_of_exercise} 
                      amount={value.amount} 
                      times={value.times} 
                      weight={value.weight} 
                      option={value.option}
                      description={value.description} 
                      unit={value.unit} 
                      media={value.media_url}
                      isCompleted={value.isCompleted} 
                      handleOnBlur= {handleOnBlur} 
                      handleTaskToggle={handleTaskToggle} 
                      isTimer={isTimer}
                      lang={lang}/>        
          })}
      </Wrapper>
      <RightBottomFloatingContainer bottom={'120px'}>
        <span onClick={() => setIsDraggable(!isDraggable)}>
          {!isDraggable ? <IoSwapVerticalSharp size={25} style={{cursor: 'pointer'}}/> : <IoCloseOutline size={25} style={{cursor: 'pointer'}} onClick={() => saveNewOrder(exercises)}/>}
        </span>
      </RightBottomFloatingContainer>
    </>
  );
};

export function ExercriseRow({ isDraggable, partId, value, index, id, name, amount, option, unit, description, times, weight, handleOnBlur, isCompleted, handleTaskToggle, isTimer, media, lang}) {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)
    let Wrapper = isDraggable ? Reorder.Item : 'div';

    return (
      <Wrapper value={value} id={value} drag={isDraggable} style={{width:'100%'}}>
        <Row padding={'5px 0 5px 1em'}>
            <h4>{name}</h4>
        </ Row>
      <WorkoutRow columns={'1fr 2.5fr 1fr 0.5fr'}>
        <WorkoutMedia src={media} type="image"/>
              <WorkoutContent align={'flex-start'}>
              {/* <p>{WordTranslations.sets[lang]}</p> */}
              <Row justify={'flex-start'}>
                  <CustomTextInput
                      input = {ExerciseDetailsInput}
                      val={String(times)} 
                      placeholder={"n/a"}
                      labelName={'times'} 
                      handleOnBlur={handleOnBlur} 
                      id={id}
                      index={index}
                      style={{marginRight: 10}}
                  /> 
                  <p style={{marginBottom: 2, display: !times && ('none')}}>x</p>
                  <CustomTextInput
                       input = {ExerciseDetailsInput}
                       val={String(amount)} 
                       placeholder={"n/a"}
                       labelName={'amount'} 
                       handleOnBlur={handleOnBlur} 
                       id={id}
                       index={index}
                       style={{marginLeft: 10}}
                  />
                 {amount && ( <p style={{margin: '0px 0px 1px 5px'}}>{option !== 'rep' ? option: `${WordTranslations.repetitions[lang].toLowerCase()}`}</p>)}
                  </Row>
              {/* </WorkoutContent>
              <WorkoutContent align={'flex-start'}> */}
                <Row width={'fit-content'} padding={'0'}>

                </Row>
              </WorkoutContent>
              <WorkoutContent align={'flex-start'}>
              <p>Weight</p>
              <Row width={'fit-content'} justify={'start'} padding={'0'}>
                <CustomTextInput
                        input = {ExerciseDetailsInput}
                        val={String(weight)} 
                        labelName={'weight'} 
                        placeholder={'n/a'}
                        handleOnBlur={handleOnBlur} 
                        id={id}
                        index={index} 
                    />   
                {weight !== null && ( <p style={{marginBottom: 4}}>{unit}</p>)}
              </Row>
              </WorkoutContent>
            <WorkoutContent width={'auto'}>               
              {description !== "" && (
                <IoInformationCircleOutline onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}size={30}/>
                )
              }
              {(isTimer && CheckExerciseId(partId)) && (
                <CustomCheckBox
                  isChecked={isCompleted}
                  onChange={isChecked => handleTaskToggle(id, isChecked)}
                />
              )}
            </WorkoutContent>
          <CustomPopUp content={description ? description : ""} isOpen ={isDescriptionVisible} setIsOpen={() => setIsDescriptionVisible(!isDescriptionVisible)} div={DescriptionContainer}/>
      </WorkoutRow>
    </Wrapper>   
    );
  }

const MyExercise = () => {
    const {language} = useLanguage();
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']
    const location = useLocation();
    const {name, values, id} = location.state || {};
    // const {personalData, setPersonalData} = useData();
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [content, setContent] = useState("");
    const [shouldTimerContinue, setShouldTimerContinue] = useState(false);
    const [reset, setReset] = useState(false);
    const [isTimer, setIsTimer] = useState(false);
    const [stopTimer, setStopTimer] = useState(false);
    const [exercises, setExercises] = useState();

    useEffect(() => {
        const orderedValue = loadOrder(id);
        sessionStorage.setItem('order-' + id, JSON.stringify(orderedValue));
        setExercises(AddIsComplete(orderedValue))
        setIsTimer(() => localStorage.getItem('currentlyRun') ? true : false);
    },[])

    const loadOrder = () => {
      const savedOrder = sessionStorage.getItem('order-' + id);
      return savedOrder ? JSON.parse(savedOrder) : (values[0].part_of_workout_name ? values.slice(1) : values);
    };

    const handleTaskToggle = (exerciseId) => {
      setExercises(exercises => exercises.map(exercise => 
          exercise.ExerciseId === exerciseId ? {... exercise, isCompleted: !exercise.isCompleted} : exercise));
     };

     const handleContinue = useCallback(() => {
      setIsPopUpOpen(false);
      setShouldTimerContinue(prev => !prev); // Kääntää tilan arvon

    }, []);

    const handleConfirm = async (completed) => {
      const currentData = await JSON.parse(localStorage.getItem("currentlyRun"));

      // Lisätään uusi arvo tietoihin
      // currentData.id = id;
      currentData.completionRate = completed?.rate;
      currentData.completed = completed?.completed;
      currentData.endTime = Date.now();

      const index = await getLast(Object.keys(localStorage), 'performance-');

      // Tallennetaan päivitetyt tiedot takaisin LocalStorageen
      SetToLocalStorage("performance-" + (parseInt(index) + 1) , currentData)
      setStopTimer(true);
      handleClose();
      // toast.dismiss();
    }

    const handleClose = () => {
      setIsTimer(false)
      setIsPopUpOpen(false);
      setReset(true);
      ClearCheckbox(exercises);
    };
   
    const ConfirmationPopUp = () => {
      const buttons = [
        // { label: 'Ei', onClick: handleCancel, color: Colors.red},
        { label: `${WordTranslations.yes[lang]}`, onClick: () => handleConfirm(CountCompletionRate(exercises))},
        { label: `${WordTranslations.continue[lang]}`, onClick: handleContinue}
      ];

      const message =`${NewWorkoutTranslations.my_confirm_message[lang]}`;

      setContent({message: message, buttons: buttons});
      setIsPopUpOpen(!isPopUpOpen);
    }
    
     return(
          <ExerciseContainer>
            <ExerciseContent>
             {exercises && (
               <div>
                 <Title>{values[0]?.part_of_workout_name}</Title>
                 <DraggableList items={exercises} id={id} handleTaskToggle={handleTaskToggle} setExercises={setExercises} exercises={exercises} isTimer={isTimer} lang={lang}/>
               </div>
             )}
            </ExerciseContent>
          <Timer name = {{name: name, partname: values[0]?.part_of_workout_name}}
                  shouldContinue={shouldTimerContinue} setTimer = {setIsTimer}
                  setStop = {setStopTimer} stop = {stopTimer}  
                  ConfirmationPopUp={ConfirmationPopUp} 
                  onContinueChange={handleContinue} 
                  reset={reset} 
                  onTimerReset={() => setReset(false)}
                  partId={id}/>
          <CustomPopUp content = {content} isOpen={isPopUpOpen} setIsOpen={() => handleClose()} div={DescriptionContainer}/>
          </ExerciseContainer>
     )
   }

export default MyExercise;
  

