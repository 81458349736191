import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
//utils
import { useWebSocket, useData, useAuth, useLanguage } from "../../Utils/UseContext";
//API
import { GetPublicData, PatchNotificationStatus } from "../../Utils/ApiRequests";
//components
import { Container, ScrollAuto } from "../../components/style/Container.styled";
import { Column, Row } from "../../components/style/Row.styled";
import { WorkoutContent, WorkoutRow } from "../Workout/StyledComponents";
import { CardList, ScrollView } from "../../components/Components";
import { CustomButton, ExitButton } from "../../components/Buttons";
//style
import { Card } from "../../components/style/Card.style";
import { SquareImage } from "../../components/style/image.styled";
import { StyledBorderSquareButton } from "../../components/style/Buttons.styled";
import image from '../../components/style/images/gym_workout.jpg';


const Notifications = () => {
    const {language} = useLanguage();
    const navigate = useNavigate();
    const ws = useWebSocket();
    const {notifications, setNotifications} = useData();
    const {state} = useAuth();
    const notificationData = notifications?.notifications;


    useEffect(() => {
        if(state?.isAuthenticated && ws){
            GetPublicData({setNotifications, ws});
        }
    },[ws])

    const navTo = (item) => {
        const token = state?.token;
        navigate(item?.url, {state: {item, token }})
    }

    const DeleteNotification = async (index, id) => {
        await PatchNotificationStatus(id)
    }

    
    const notificationList = notifications.notifications.map((item) => {
       
        return(
            <WorkoutRow height={2.3} columns={'1fr 0.5fr 0.2fr'}>

            <WorkoutContent align={'flex-start'}>
                <h3 style={{marginBottom: 20}}>{item?.title}</h3>
                {item?.body?.body}
            </WorkoutContent>
            <WorkoutContent align={'flex-start'}>
                <h5 style={{marginBottom: 20}}>The workout included</h5>
                {item?.body?.parts.map((name) => <li>{name?.part_of_workout_name}</li>)}
            </WorkoutContent>
            <WorkoutContent align={'flex-end'} justify={'flex-start'}>
              {'X'}
              {item?.url && (<p onClick={() => navigate('/workout'+item?.url)}>Rate</p>)}
            </WorkoutContent>
        </WorkoutRow>
        )
    })

    return(
        <Container direction={'column'}>
            <h4>Notifications</h4>
            <Row padding={'10px 0px 10px 0px'} justify={'center'} justify_mobile={'center'}>
                    <p>You have {notificationData?.length} notifications</p>
            </Row>
            {/* <ScrollView items = {notificationList} renderItem={(item) => <span>{item}</span>}/> */}
            <CardList>
                {notificationData.map((item, index) => (
                    <Card key={index}>
                        <p style={{fontSize: 10, marginBottom: 2}}>The notification has been sent {new Date(item?.created_at).getDate() + '.' + new Date(item?.created_at).getMonth() + "." + new Date(item?.created_at).getFullYear()}</p>
                        <Row padding={'0px'}>
                            <h2>{item?.title}</h2>
                            <ExitButton label={'X'} onClick={() => DeleteNotification(index, item?.id)}/>
                        </Row>
                        <Row padding={'0px'} margin={'20px 0 30px 0'}>
                            <ScrollAuto width={'250px'}>
                                <p>{item?.body?.body}</p>
                            </ScrollAuto>
                        </Row>
                        <Column margin={'10px 0 10px 0'} height={'100px'}>
                            <Row padding={'0'} width={'250px'} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                <h4>{item?.body?.name}</h4>
                            </Row>
                            <Row padding={'0px'} align={'start'} justify={'flex-start'}  height={'65px'}>
                                {!item?.body?.image && (
                                    <SquareImage src={image} width={'60px'} height={'60px'} style={{marginRight: 20}}/>
                                )}
                                {item?.body?.parts.length > 0 && (
                                    <ScrollView items = {item?.body?.parts.map((name) => {return (name?.part_of_workout_name)})} renderItem={(item) => <span>{item}</span>} /> 
                                )}
                            </Row>
                        </Column>
                        <Row padding={'0px'} justify={'flex-end'} justify_mobile={'flex-end'}>
                            {item?.url && (
                                <CustomButton button={StyledBorderSquareButton} label={'Action'} onClick={() => navTo(item)}/>
                            )}
                        </Row>

                    </Card>
                ))}
            </CardList>

        </Container>
    )
}

export default Notifications;