import React, {useEffect, useState} from "react";
//components
import { StyledCustomInput, StyledTextArea } from "./style/CustomInput.styled";
import { AuthContainer, AuthContent, FormContainer } from '../components/style/Container.styled.jsx';
import { CustomButton } from "./Buttons";
//Styles
import { StyledSubmitButton } from "./style/Buttons.styled";
//API
import { ContactEmail } from "../Utils/ApiRequests.jsx";
import { useLanguage } from "../Utils/UseContext.jsx";


const Message = ({mes, success}) => {
    const {language} = useLanguage();
    const message = mes;
    return <p style={{marginBottom: 20, textAlign: 'center'}}>{message}</p>;
}

const Contact = () => {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        title: "",
        message:""
    });

    const [isValidEmail, setIsValidEmail] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState({message:"", success: null});

    useEffect(() =>{
        if(messageSuccess?.success === true){
            clearFromaState();
        }
    },[messageSuccess])

    const clearMessage = () => {
        setMessageSuccess({
            message:"",
            success: null,
            showMsg: false
        })
    }

    const clearFromaState = () =>{
        setFormData({
            firstname: "",
            lastname: "",
            email: "",
            title: "",
            message:""
        })
    }

    const validateEmail = (text) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(text);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));

        if (name === "email") {
            const isValid = validateEmail(value);
            setIsValidEmail(isValid);
          }

        clearMessage();
      };

      const isFormValid = () => {
        return Object.values(formData).every((value) => value.length > 1);
      };


    return(
        <AuthContainer>
        {/* <AuthContent> */}
        <FormContainer>
        <Message mes={messageSuccess?.message} success={messageSuccess?.success} showMsg={messageSuccess?.showMsg}/>

        <StyledCustomInput
            placeholder="Firstname"
            name="firstname"
            value={formData.firstname}
            onChange={handleInputChange}
            //   onFocus={() => (setUndefinedErr(false))}
            />            
        <StyledCustomInput
            placeholder="Lastname"
            name="lastname"
            value={formData.lastname}
            onChange={handleInputChange}
          //   onFocus={() => (setUndefinedErr(false))}
          />
        <StyledCustomInput
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            //   onFocus={() => (setUndefinedErr(false))}
            />
        <StyledCustomInput
            placeholder="Title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            //   onFocus={() => (setUndefinedErr(false))}
            />
        <StyledTextArea 
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            />
        <CustomButton 
            button={StyledSubmitButton}
            label={'Send'}
            style={{marginTop: '30px'}}
            disabled={!isFormValid() || !isValidEmail}
            onClick={() => ContactEmail(formData, setMessageSuccess)}
            />
        </FormContainer>
            {/* </AuthContent> */}
            </AuthContainer>
            
    )
}

export default Contact;