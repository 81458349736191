import React, { useState, useEffect, useCallback,useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
//Utils
import { HasRole} from "../../../Utils/Role.jsx";
import { OptimizeImage, TextMinLenCount, CapitalizeFirstLetter } from "../../../Utils/Utils.jsx";
import { useData, useLanguage} from "../../../Utils/UseContext.jsx";

// Icons
import { MdInfoOutline, MdFormatListBulletedAdd, MdDone} from "react-icons/md";
import { IoTrash } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack, IoMdClose } from "react-icons/io";
// API and Components
import { InsertNewWorkoutToDb, InsertNewExercise, InsertNewVideo, InsertNewTrainingplan } from "../../../Utils/ApiRequests.jsx";
import { SubmitButton, CustomButton } from "../../../components/Buttons.jsx";
import { ScrollView, CustomPopUp, CustomRadioButton, Modal } from "../../../components/Components.jsx";
import NewWorkoutSelection from "./NewWorkoutSelection.jsx";
import { ExerciseDetailsInput } from "../../../components/style/CustomInput.styled.jsx";
import { CustomTextInput } from "../../../components/Components.jsx";

// STYLE
import { LABEL, StyledWorkoutInput, StyledTextArea, StyledCustomInput } from "../../../components/style/CustomInput.styled.jsx";
import { Column, Row } from "../../../components/style/Row.styled.jsx";
import { WorkoutDetailsRow, WorkoutContent, WorkoutInputContainer, WorkoutInputContent, WorkoutPartContainer, Title, WorkoutRow, WorkoutBox } from "../StyledComponents.jsx";
import { Container, ButtonContainer, NativeSelect, DescriptionContainer, PreviewContainer, PreviewImage, DropzoneContainer, PlaceholderText, StyledVideoContainer, StyledPlannerContainer, BottomStickyContainer} from "../../../components/style/Container.styled.jsx";
import { StyledHalfRoundLeft, StyledHalfRoundRight, StyledSubmitButton, StyledAddButton } from "../../../components/style/Buttons.styled.jsx";
import { NowrapH4 } from "../../../components/style/paragraph.styled.jsx";

//toast
import { toast } from 'react-toastify';
//Translations
import WordTranslations from '../../Translations/WordTranslation.json'
import NewWorkoutTranslations from '../../Translations/NewWorkout.json'


const Media = ({item}) => {
    if(!item?.file) return;

    return(
        <>
            {item?.file !== null && item?.file.type.startsWith('video/') ? (
                <video controls width="100px" maxHeight="100px">
                    <source src={item.previewUrl} type={item.file.type} />
                    Your browser does not support the video tag.
                </video>)
            :
            (<img src={item.previewUrl} alt="<img>" style={{ maxWidth: '60px', maxHeight: '60px', borderRadius:5 }} />
            )}
        </>
    )
}

const extractMetadata = (file) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);

      videoElement.onloadedmetadata = () => {
        const metadata = {
          duration: videoElement.duration, // Videon kesto sekunteina
          width: videoElement.videoWidth,  // Videon leveys
          height: videoElement.videoHeight, // Videon korkeus
          type: file.type,                 // Tiedoston tyyppi
          size: (file.size / (1024 * 1024)).toFixed(2),
          name: file?.name, // Tiedoston koko MB
        };
        resolve(metadata); // Palauta metadata ratkaistuna Promisena
      };

      videoElement.onerror = () => {
        reject("Metadatan lataus epäonnistui."); // Palauta virhe jos lataus epäonnistuu
      };
    });
  };

export const Dropzone = ({ roleCheck, content, setContent, accept, placeholder, width, height, theme, metadata, setMetadata}) => {
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const type = content?.file?.type.startsWith('video/') ? 'video' : 'image';
    const is_file = content?.file ? true : false;

    const handleFile = async (file) => {
        if (!await HasRole(roleCheck ? roleCheck : 'advanced')) {
            toast.info('This is advanced feature!')
            setError('!');
            return;
        }

        handleRemove()

        const maxSize = 50 * 1024 * 1024; // 50MB
        if (file.size > maxSize) {
          setError('File size exceeds the limit of 50MB');
          return;
        }

        if (file.type.startsWith('image/')) {

            let previewUrl = null;
            const optimizedImage = await OptimizeImage(file);
            previewUrl = URL.createObjectURL(optimizedImage);

            // setPreview(previewUrl);
            setError(null);
            setContent({
                ...content,
                file: optimizedImage,
                previewUrl
            });

        }else{ //video
            const meta = await extractMetadata(file);

            let previewUrl = null;
            previewUrl = URL.createObjectURL(file);
            // setPreview(previewUrl);
            setError(null);
            setContent({
                ...content,
                file,
                previewUrl
            });
           setMetadata(meta);
        }
      };

    const onDrop = useCallback(async (acceptedFiles) => {

        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];

            // Check file size (e.g., limit to 5MB)
            const maxSize = 5 * 1024 * 1024 * 10;

            await handleFile(file);
        }
    }, [content, setContent]);

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        onDrop,
        accept
     });

       // Avataan tiedostonvalinta, kun painiketta painetaan
  const handleManualUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
    }
  };

  // Tiedoston käsittely painikkeen kautta
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
        const file = files[0];
        await handleFile(file);
    }
  };

  const handleRemove = () => {
    setPreview(null);
    setContent({
      ...content,
      file: null,
      previewUrl: null,
    });
    setError(null);
  };

  const timeString = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    const millis = Math.floor((seconds % 1) * 1000);

    const paddedSecs = secs.toString().padStart(2, '0');
    const paddedMillis = millis.toString().padStart(3, '0');

    return `${minutes}:${paddedSecs}:${paddedMillis}`;
  }

    return (
        <Column width={'100%'} height={height} align={'center'}>
        <DropzoneContainer {...getRootProps()} width={width} height={height} border={!is_file ? `2px dashed ${theme.colors.border}` : 'none'}>
            <input {...getInputProps()} />
            <input
              type="file"
              accept={Object.keys(accept).join(',')}
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
            {content?.previewUrl ? (
                <>
                    {content?.file !== null && content?.file.type.startsWith('video/') ? (
                        // <Row padding={'0'}>
                        <StyledVideoContainer>
                            <video controls style={{overflow:'hidden', border: `1px solid ${theme.colors.border}`, borderRadius: 6, maxWidth: '100%',
                                maxHeight: '100%',
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain'}}>
                                <source src={content?.previewUrl} type={content.file.type} />
                                Your browser does not support the video tag.
                            </video>
                            <Column width={'30%'} mobile_width={'auto'} height={'auto'} justify={'space-between'} align={'start'}>
                                <div style={{textAlign: 'start', fontSize: 13}}>
                                    <p>Name: {metadata?.name}</p>
                                    <p>Duration: {timeString(metadata?.duration)}</p>
                                    <p>Size: {metadata?.size}MB</p>
                                </div>

                                {is_file && (<CustomButton label={`Upload new ${type}`} onClick={handleManualUpload} button={StyledSubmitButton} style={{marginTop: 10, fontSize:11}}/>)}

                            </Column>
                            </StyledVideoContainer>
                        // </Row>
                    ) : (
                        <PreviewImage src={content?.previewUrl} alt="Preview" />

                    )}
                </>
                ):(<PlaceholderText>{isDragActive ? `Drop the files here...` : placeholder}</PlaceholderText>)
                }
                {error && <p style={{ color: 'red', position: 'absolute', bottom: '10px' }}>{error}</p>}
              </DropzoneContainer>
              {(is_file && !content?.file.type.startsWith('video/')) && (<CustomButton label={`Upload new ${type}`} onClick={handleManualUpload} button={StyledSubmitButton} style={{marginTop: 10, fontSize:11}}/>)}
        </Column>
    );
  };

const TrainingPlanSection = ({theme, newTrainingPlan, setNewTrainingPlan, lang}) => {
    const navigate = useNavigate();
    const {exercisesData} = useData();
    const [days, setDays] = useState([]);
    const [duration, setDuration] = useState(0); // Asetetaan oletukseksi 0 päivää
    const [exercises, setExercises] = useState([]); // Harjoitukset tietokannasta
    const [isModalOpen, setIsModalOpen] = useState();
    const [copyInterval, setCopyInterval] = useState(0);
    const [tempExercises, setTempExercises] = useState([]);
    const [count, setCount] = useState({workout: 0, rest: 0});
    const [partname, setpartname] = useState("");
    const [infoOpen, setInfoOpen] = useState(false);

    const deviceScreenSize = {
        width: window.screen.width,
        height: window.screen.height,
      };

    // console.log("data: ", exercisesData)
    // Esimerkki harjoituksista, jotka voisivat tulla tietokannasta
    useEffect(() => {
      // Oletetaan, että haetaan harjoitukset tietokannasta
        const data = exercisesData?.data;
        setExercises(data);

        const rawSession = sessionStorage.getItem('training_plan');
        // console.log("Raw session data:", rawSession);

        try {
          const session = JSON.parse(rawSession);
          if (session && Array.isArray(session) && session.length > 0) {
            setDays(session);
          }
        } catch (error) {
          console.error("Failed to parse sessionStorage data:", error);
        }
    }, []);

    useEffect(() => {

      if(days.length > 0){
        sessionStorage.setItem('training_plan', JSON.stringify(days));
      }
      const workoutCount = days.filter((day) => day.status === 'workout').length;
      const restCount = days.filter((day) => day.status === 'rest').length;

      setCount({workout: workoutCount, rest: restCount});
      setNewTrainingPlan({
        ...newTrainingPlan,
        days: days
      })
    },[days])

    // Generoi päivät ja alustaa tilan (status ja valitut harjoitukset)
    const generateDays = (daysCount) => {
        setDays((prevDays) => {
            const newDays = [...prevDays];
            for (let i = prevDays.length; i < daysCount; i++) {
                newDays.push({
                    status: 'rest',
                    part_of_workout_name: '',
                    exercises: [],
                });
            }
            return newDays.slice(0, daysCount); // Leikkaa ylimääräiset päivät pois, jos kesto lyhenee
        });
    };

    // Päivän tilan päivitys
    const updateDayStatus = (index, status) => {
    //   const session = sessionStorage.getItem('training_plan');
    setDays((prevDays) => {
        return prevDays.map((day, i) => {
            if (i === index) {
                // Päivitetään vain valitun päivän tila
                return {
                    ...day,
                    part_of_workout_name: day.part_of_workout_name,
                    status: status,
                    exercises: status === 'rest' ? [] : day.exercises, // Tyhjennä harjoitukset, jos tila on "rest"
                };
            }
            return day; // Muut päivät pysyvät ennallaan
        });
    });

    // Jos uusi tila on "workout", avaa modal kyseiselle päivälle
    if (status === 'workout') {
        setIsModalOpen(index);
    }

    };

    // Harjoituksen valinnan päivittäminen
    const updateDayExercises = (selectedExercise) => {
        setTempExercises((prevExercises) => {
            if (prevExercises.includes(selectedExercise)) {
                // Poistetaan harjoitus, jos se on jo valittu
                return prevExercises.filter((ex) => ex !== selectedExercise);
            } else {
                // Lisätään harjoitus, jos sitä ei ole vielä valittu
                return [...prevExercises, selectedExercise];
            }
        });
    };

    // Suoritetaan, kun käyttäjä syöttää keston
    const handleDurationChange = (name, value, id, index) => {
    //   const value = parseInt(e.target.value)
        const duration = parseInt(value) > 365 ? 365 : parseInt(value)

      setDuration(duration);
    };

    const closeModal = () => {
        setDays((prevDays) => {
            return prevDays.map((day, i) => {
                if (i === isModalOpen) {
                    // Päivitetään vain valitun päivän tila
                    return {
                        ...day,
                        status: 'rest',
                        exercises: []
                    };
                }
                return day; // Muut päivät pysyvät ennallaan
            });
        });
        setCopyInterval(0);
        setIsModalOpen();
    }

    const saveDayContent = () => {
        const index = isModalOpen;
        setDays((prevDays) => {
            const newDays = [...prevDays];
            newDays[index].exercises = tempExercises; // Päivitetään vain nykyinen päivä
            newDays[index].part_of_workout_name = partname;
            return newDays;
        });
        if(copyInterval > 0){
            copyDayContentEveryNthDay(index, copyInterval)
        }
        setCopyInterval(0);
        setTempExercises([]);
        // sessionStorage.setItem('training_plan', days);
        setIsModalOpen();
    }

    const copyDayContentEveryNthDay = (dayIndex, interval) => {

        setDays((prevDays) => {
            return prevDays.map((day, i) => {
                if ((i - dayIndex) % interval === 0 && i > dayIndex) {
                    // Päivitä joka N. päivä kopioimalla harjoitukset ja tila
                    return {
                        ...day,
                        exercises: [...prevDays[dayIndex].exercises], // Kopioi harjoitukset
                        status: prevDays[dayIndex].status, // Kopioi tila
                        part_of_workout_name: prevDays[dayIndex].part_of_workout_name,
                    };
                }
                return day; // Muut päivät pysyvät muuttumattomina
            });
        });
      };

      const handleOnBlur = async (name, value, id, index) => {

        if(value){
            setDays((prevState) => {
                const newData = [...prevState];
                if (newData[index]) {
                  const updatedDay = { ...newData[index] }; // Kopioi päivä
                  const updatedExercises = [...updatedDay.exercises]; // Kopioi harjoitukset

                  updatedExercises[id] = {
                    ...updatedExercises[id],
                    [name]: value, // Päivitä arvo
                  };

                  updatedDay.exercises = updatedExercises;
                  newData[index] = updatedDay; // Päivitä päivään

                  return newData; // Palauta uusi tila
                }
                return prevState;
              });

        }else{
          console.error(" KEY IS NOT VALID")
        }

      }

      const handlePartNameBlur = async (name, value, id, index) => {
            setpartname(value)
      }

    // Käytetään useEffectiä päivittämään päivät aina, kun kesto muuttuu
    useEffect(() => {
      generateDays(duration); // Generoidaan päivät aina keston muutoksen jälkeen
    }, [duration]);

    const DaySection = () => {
       return(
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '50%', maxHeight: '55vh', marginTop: '10px', paddingBottom: 55, overflow: 'auto'}}>
            <div style={{marginBottom: 10}}>
              <label>{WordTranslations.duration[lang]} </label>
            <CustomTextInput
                input = {ExerciseDetailsInput}
                val={String(duration)}
                labelName={'duration'}
                handleOnBlur={handleDurationChange}
                id={'2'}
                index={0}
                type={'number'}
                minWidth={300}
            />
              <span> {WordTranslations.day.plural[lang]?.toLowerCase()}</span>
            </div>
          {days.map((day, index) => (
            <div key={index} style={{ border: `1px solid ${theme.colors.border}`, borderRadius: 5, maxHeight:'90px', padding: '10px', margin: '2px 0px 2px 0px' }}>
                <Row padding={'0px'}>
                    <h4>{WordTranslations.day.singular[lang]} {index + 1} {day?.status === "workout" ? ` - ${day?.part_of_workout_name}` : ''}</h4>
                    <select
                      value={day.status}
                      onChange={(e) => updateDayStatus(index, e.target.value)}
                      style={{color:theme.colors.text, background: theme.colors.body, borderRadius: 5}}
                    >
                      <option value="rest">{WordTranslations.rest[lang]}</option>
                      <option value="workout">{NewWorkoutTranslations.exercise[lang]}</option>
                      {/* <option value="otrher">Muu</option> */}
                    </select>
                </Row>
                <Column style={{overflow: 'auto', maxHeight: '50px'}}>
                    {days[index]?.exercises.length > 0 && (
                        days[index]?.exercises.map((item, i) => (
                            <Row key={i} padding={'0px 28px 0px 0px'}>
                                <p key={i} style={{fontSize: 12}}>{item?.name_of_exercise}</p>
                                <div>
                                    <CustomTextInput
                                        input = {ExerciseDetailsInput}
                                        val={String(item?.times)}
                                        labelName={'times'}
                                        handleOnBlur={handleOnBlur}
                                        id={i}
                                        index={index}
                                    />
                                    <span> x </span>
                                    <CustomTextInput
                                        input = {ExerciseDetailsInput}
                                        val={String(item?.amount)}
                                        labelName={'amount'}
                                        handleOnBlur={handleOnBlur}
                                        id={i}
                                        index={index}
                                    />
                                </div>
                            </Row>
                        ))
                    )}
                </Column>
            </div>
          ))}
          {days.length <= 0 && (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', border: `1px solid ${theme.colors.border}`, borderRadius: 10, height: '100%'}}>
                <p>{NewWorkoutTranslations.empty_days[lang]}</p>
            </div>
          )}
            <Modal
                  isOpen={isModalOpen >= 0}
                  onClose={() => closeModal()}
                  bg={'rgba(0, 0, 0, 0.2)'}
                  children = {
                      <Column justify={'space-between'} style={{background: theme.colors.body, padding: '30px', height:'80vh'}}>
                        <CustomTextInput 
                            input = {StyledWorkoutInput}
                            val={partname}
                            labelName={'training_plan_part_name'}
                            handleOnBlur={handlePartNameBlur}
                            id={'2'}
                            index={1}
                            maxLength={100}
                        />
                        <div style={{overflow: 'auto'}}>
                            <h5>{NewWorkoutTranslations.select_exercises[lang]}</h5>
                            {exercises && (exercises.map((exercise, i) => (
                              <label key={i} style={{ display: 'block' }}>
                                <input
                                  type="checkbox"
                                  checked={tempExercises.includes(exercise)}
                                  onChange={() => updateDayExercises(exercise)}
                                />
                                {exercise?.name_of_exercise}
                              </label>
                            )))}
                        </div>
                        <div>
                          {/* <CustomButton button={StyledSubmitButton} label={'Create new exercise'} onClick={() => navigate('/workout/new')}/> */}
                        <div>
                          <label>{NewWorkoutTranslations.repeat_every[lang]} </label>
                          <input
                            type="number"
                            min="0"
                            value={copyInterval}
                            onChange={(e) => setCopyInterval(parseInt(e.target.value))} // Tallenna väli stateen
                            style={{width: '80px', background: theme.colors.body, color: theme.colors.text, border: 'none'}}
                          />
                          <span> {WordTranslations.day.singular[lang]?.toLowerCase()}</span>
                          </div>
                        </div>
                        <CustomButton button={StyledSubmitButton} disabled={tempExercises.length <= 0} label={WordTranslations.save[lang]} onClick={() => saveDayContent()}/>
                    </Column>
                 }
               />
        </div>
      )
    }

    const handleOnDescriptionBlur = (name, value, id, index) => {

        setNewTrainingPlan({
            ...newTrainingPlan,
            description: value
        })
    }

    const handleOnNameBlur = async (name, value, id, index) => {
            setNewTrainingPlan({
                ...newTrainingPlan,
                name: value
            })
      }

    const InfoSection = () => {
        return (
            <>
                {deviceScreenSize?.width < 601 && (
                    <Row justify={'flex-end'} align={'start'}>
                        <div></div>
                        <>
                        {infoOpen ? <IoIosArrowDown onClick={() => setInfoOpen(!infoOpen)}/> : <IoIosArrowBack onClick={() => setInfoOpen(!infoOpen)}/>}
                        </>
                    </Row>
                )}
            <Column height={infoOpen && ('0px')} style={{overflow: 'hidden'}}>
                <Column height={'fit-content'} style={{marginBottom: 10}}>
                    <label>{WordTranslations.name[lang]}</label>
                <CustomTextInput 
                    input = {StyledWorkoutInput}
                    val={String(newTrainingPlan?.name)}
                    labelName={'training_plan_name'}
                    handleOnBlur={handleOnNameBlur}
                    id={'2'}
                    index={1}
                    maxLength={50}
                />
                </Column>
                <Column height={'fit-content'} style={{marginBottom: 20}}>
                    <label>{WordTranslations.description[lang]}</label>
                    <CustomTextInput 
                        input = {StyledTextArea}
                        val={String(newTrainingPlan?.description)}
                        labelName={'training_plan_description'}
                        handleOnBlur={handleOnDescriptionBlur}
                        id={'2'}
                        index={1}
                        maxLength={500}
                        width={'100%'}
                    />
                </Column>
            <Row padding={'0'} justify={'flex-start'} align={'start'}>
                <div>
                    <Dropzone
                        width={'120px'}
                        height={'120px'}
                        theme={theme}
                        placeholder={NewWorkoutTranslations.upload_image[lang]}
                        content={newTrainingPlan}
                        setContent={setNewTrainingPlan}
                        accept={{
                            'image/*': ['.jpeg', '.jpg', '.png', '.gif']
                            // 'video/*': ['.mp4', '.mov', '.avi']
                        }}
                    />
                </div>
                <div style={{marginLeft: 20}}>
                    <h3>{WordTranslations.info[lang]}</h3>
                    <p>{count.workout} {WordTranslations.training[lang]?.toLowerCase()} {WordTranslations.day.plural[lang]?.toLowerCase()}</p>
                    <p>{count.rest} {WordTranslations.rest[lang]?.toLowerCase()} {WordTranslations.day.plural[lang]?.toLowerCase()}</p>
                </div>
            </Row>
            </Column>
            </>
        )
    }

    return (
     <div style={{height: '76vh', width: '100%'}}>
        <h1 style={{textAlign: 'center'}}>{NewWorkoutTranslations.training_plan[lang]}</h1>
      <StyledPlannerContainer>
        <InfoSection 
            newTrainingPlan={newTrainingPlan}
            setNewTrainingPlan={setNewTrainingPlan}
        />
        <DaySection />
        </StyledPlannerContainer>
      </div>
    );
}

const Videosection = ({newVideo, setNewVideo, theme, creationMode, setMetadata, metadata}) => {

    const handleOnChange = (value, name) => {
        const sanitizedValue = value.replace(new RegExp('[/]', 'g'), '');

        setNewVideo({...newVideo, [name]: sanitizedValue})
    }

    return(
        <Column justify={'center'} align={'center'} width={'80vw'}>
            <p>Video</p>
            <WorkoutInputContent>
                <LABEL>Name:</LABEL>
                <StyledCustomInput
                    name="name"
                    type="text"
                    placeholder="Name..."
                    value={newVideo?.name}
                    onChange={(e) => handleOnChange(e.target.value, e.target.name)}
                />
            </WorkoutInputContent>
            <WorkoutInputContent>
                <LABEL>Description:</LABEL>
                <StyledTextArea
                    name="description"
                    type="text"
                    placeholder="description..."
                    value={newVideo?.description}
                    onChange={(e) => handleOnChange(e.target.value, e.target.name)}
                    width={'100%'}
                />
                <p style={{fontSize: 10, color: theme.colors.text}}>{newVideo?.description.length}/50</p>
            </WorkoutInputContent>
            <Row padding={'0'}>
            <Dropzone
                theme={theme}
                placeholder={'Upload video'}
                width={'100%'}
                height={'100%'}
                content={newVideo}
                setContent={setNewVideo}
                setMetadata={setMetadata}
                metadata={metadata}
                accept={{
                    // 'image/*': ['.jpeg', '.jpg', '.png', '.gif']
                    'video/*': ['.mp4', '.mov', '.avi']
                }}
            />
            </Row>
        </Column>

    )
}

const PreviewRow = ({exercise, workout, setExerciseList, lang}) => {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)
    const list = Object.values(exercise)[0];
    const key = Object.keys(exercise)[0];

    const handleOnDelete = (index) => {
        const updatedList = [...workout]
        const objIndex = workout.findIndex((k) => Object.keys(k)[0] === key)
        const handledList = Object.values(updatedList[objIndex])[0];

        handledList.splice(index, 1);
        if(handledList.length <= 0){
            updatedList.splice(objIndex,1)
        }
        setExerciseList(updatedList)
    }


    const content = list.map((item, index) => {

        return(
            <>
                <Row padding={'5px 0px 5px 0px'}>
                    <p>{item?.name_of_exercise}</p>
                </Row>
                <WorkoutRow columns={!item.file && ('0fr 1fr 1fr 1fr 0.5fr')}>
                        <WorkoutContent >
                            <Media item={item}/>
                        </WorkoutContent>
                        <WorkoutContent align={'flex-start'}>
                            {/* <p>Amount</p>
                            <Row justify={'start'}>
                                <p>{item.amount}</p>
                            </Row> */}
                            <p>{item?.times} {item?.times && ('X')} {item?.amount} {item?.amount && (item?.options === 'rep' ? `${WordTranslations.repetitions[lang]}` : (item?.options))}</p>
                        </WorkoutContent>
                        <WorkoutContent>

                        </WorkoutContent>
                        <WorkoutContent align={'flex-end'}>
                            {/* <p>Paino</p> */}
                            {/* <p>{item.weight}</p> */}
                            <span style={{fontSize: 20}}>
                                {item?.weight} {item?.unit}
                            </span>
                        </WorkoutContent>
                      <WorkoutContent>
                        <IoTrash onClick={() => handleOnDelete(index)} size={20} style={{cursor: 'pointer'}}/>
                        {item.description !== "" && (
                          <MdInfoOutline onClick={() => setIsDescriptionVisible(!isDescriptionVisible)} size={30} color={'white'} style={{cursor: 'pointer'}}/>
                          )
                        }
                      </WorkoutContent>
                </WorkoutRow>
                <CustomPopUp content={item.description} isOpen ={isDescriptionVisible} setIsOpen={() => setIsDescriptionVisible(!isDescriptionVisible)} div={DescriptionContainer}/>

            </>
        )
    })

    return(
        <>
            <h4 style={{marginTop: 20}}>{Object.keys(exercise)[0]}</h4>
            {content}
        </>
    )
}

const ExerciseListPreview = ({exerciseList, setExerciseList, setPreview, lang}) => {

    const handleOnDelete = (index) => {
        // Luo uusi taulukko ilman poistettavaa itemiä
        const updatedList = exerciseList.filter((_, i) => i !== index);

        // Päivitä tila uudella taulukolla
         setExerciseList(updatedList);
        if(exerciseList.length <= 0 || updatedList.length <= 0){
            setPreview(false)
        }
    }

    const previewRow = exerciseList.map((item, index) => (
       <WorkoutRow columns={'1fr 8fr'}>
        <WorkoutBox column={'1 / 2'} row={'1 / 3'}>
            <Media item={item}/>
        </WorkoutBox>
        <WorkoutBox column={'2 / 2'} row={'1 / 2'}>
            <Row padding={'0'}>
                <NowrapH4>{item?.name_of_exercise}</NowrapH4>
                <IoTrash onClick={() => handleOnDelete(index)} size={20} style={{cursor: 'pointer'}}/>
            </Row>
        </WorkoutBox>
        <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr', width: '100%' }}></div>
        <WorkoutBox column={'2 / 2'} row={'2 / 2'}>
             <Row padding={'0px 30px 0px 0px'}>
                <Column>
                    <p>{item?.times} {item?.times && ('X')} {item?.amount} {item?.amount && (item?.options === 'rep' ? `${WordTranslations.repetitions[lang]}` : (item?.options))}</p>
                </Column>
                <Column>
                    {/* <p>Weight</p> */}
                   { item?.weight && (
                    <span style={{fontSize: 20}}>
                        {item?.weight}{item?.unit}
                    </span>
                    )}
                </Column>
             </Row>
        </WorkoutBox>
     </WorkoutRow>
        ))

  return(
    <div style={{width: '50%'}}>
        {
            previewRow
        }
    </div>
  )

}

const FilteredList = ({items, setItems, selectedItem, setSelectedItem, lang}) => {
    // const [items, setItems] = useState(data);
    const [isOpen, setIsOpen] = useState(false);
    const [newItem, setNewItem] = useState('');

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelectItem = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = items.find(option => option.value === selectedValue);
        setSelectedItem(selectedValue);
        setIsOpen(false); // Sulkee dropdownin valinnan jälkeen
      };

      const handleAddItem = (event) => {
        event.preventDefault();
        if (newItem.trim() !== '') {
          const newItemTrimmed = newItem.trim();
          setItems([...items, {value: newItemTrimmed, label: CapitalizeFirstLetter(newItemTrimmed)}]);
        //   setData([...items, {value: newItemTrimmed, label: CapitalizeFirstLetter(newItemTrimmed)}]);
          setSelectedItem(newItemTrimmed); // Asettaa uuden itemin valituksi
          setNewItem(''); // Tyhjentää input-kentän
          setIsOpen(false); // Sulkee dropdownin
        }
      };

      const handleOnChange = (value) => {
        const sanitizedValue = value.replace(new RegExp('[/]', 'g'), '');
        setNewItem(sanitizedValue)
      }

      const dropdown = (options) => {
        return(
            <select style={{background: 'transparent', border: 'none', height: '5em', fontSize: '16px'}} value={selectedItem} onChange={handleSelectItem}>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
        )
    }

    return(
        <Row padding={'0'}>
                {!isOpen ?
                    <Row justify={'flex-start'}  padding={'0'}>
                        {items.length > 0 ? (dropdown(items)) : <p>{NewWorkoutTranslations.add_part_name[lang]}</p>}
                        <MdFormatListBulletedAdd onClick={() => setIsOpen(!isOpen)} size={20} style={{margin: '0px 15px 0px 15px'}}/>
                    </Row>
                    :
                    <Row justify={'center'} justify_mobile={'center'} padding={'0'}>
                        <form onSubmit={handleAddItem} style={{display: 'flex', flexDirection:'row', alignItems: 'center', width: '100%'}}>
                            <StyledCustomInput
                                height={'32px'}
                                type="text"
                                value={newItem}
                                onChange={(e) => handleOnChange(e.target.value)}
                            />
                            <CustomButton type={'submit'} button={StyledAddButton} icon={<MdDone />} style={{width: 32, height: 32, margin: '0 5px 0 5px'}}/>
                            <CustomButton onClick={() => setIsOpen(!isOpen)} button={StyledAddButton} icon={<IoMdClose />} style={{width: 32, height: 32, margin: '0 5px 0 5px'}}/>
                        </form>
                    </Row>
                }
        </Row>
    )
}

export const ExerciseSection = ({theme, disabled, partList, setPartList, selectedItem, setSelectedItem, handlePushToList, newExercise, setNewExercise, handleRadioChange, creationMode, handleSingleItem, lang}) => {
    const [selectedOption, setSelectedOption] = useState();
    const [openDropdown, setOpenDropdown] = useState(false);
    const is_single_execise = creationMode === 'exercise';

    const [options, setOptions] = useState([
        { value: 'rep', label: `${WordTranslations.repetitions[lang]}`},
        { value: 'sek', label: `${WordTranslations.seconds[lang]}` },
        { value: 'min', label: `${WordTranslations.minutes[lang]}` },
        { value: 'h', label: `${WordTranslations.hours[lang]}` },
    ]);
    const [unit, setUnit] = useState([
        {value: 'kg', label: 'Kilo'},
        {value: 'lb', label: 'Pounds'},
        {value: null, label: 'No unit'}
    ]);

    const handleOnChange = (value, name) => {
        const sanitizedValue = value.replace(new RegExp('[/]', 'g'), '');
        setNewExercise({...newExercise, [name]: sanitizedValue})
    }

    const handleChange = async (e) => {
            const value = e.target.value;
            const name = e.target.name;

            if(name === 'options'){
                setNewExercise({...newExercise, options: value})
            }else if(name === 'unit'){
                setNewExercise({...newExercise, unit: value})
            }
      };

    const dropdown = (options, name) => {
        return(
            <select name={name} style={{background: 'transparent', border: 'none', height: '3em'}} value={selectedOption} onChange={handleChange} onClick={() => setOpenDropdown(!openDropdown)}>
                {options.map((option, index) => (
                  <option  key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
        )
    }


    return (
        <>
        <WorkoutInputContainer>
        </WorkoutInputContainer>
        {!is_single_execise && (
        <WorkoutInputContainer>
            <FilteredList items={partList} setItems={setPartList} selectedItem={selectedItem} setSelectedItem={setSelectedItem} lang={lang}/>
        </WorkoutInputContainer>
        )}
        <form onSubmit={!is_single_execise ? handlePushToList : handleSingleItem} style={{display:'flex', justifyContent:'center',alignItems:'center', height:'100%'}}>
        <WorkoutInputContainer>
         <WorkoutInputContent>
            <LABEL>{WordTranslations.name[lang]}</LABEL>
            <StyledCustomInput
                name="name_of_exercise"
                type="text"
                value={newExercise?.name_of_exercise}
                onChange={(e) => handleOnChange(e.target.value, e.target.name)}
            />
            </WorkoutInputContent>
            <div className="amounts_div">
                <div className="amount_inside_div rounds_div">
                    <WorkoutInputContent>
                        <LABEL>{WordTranslations.sets[lang]}</LABEL>
                        <StyledCustomInput
                            name="times"
                            type="number"
                            value={newExercise?.times}
                            onChange={(e) => setNewExercise({...newExercise, times: e.target.value})}
                        />
                    </WorkoutInputContent>
                </div>
                <div className="amount_inside_div repetition_div">
                    <WorkoutInputContent>
                        <LABEL>{WordTranslations.repetitions[lang]}</LABEL>
                        <StyledCustomInput
                            name="amount"
                            type="number"
                            value={newExercise?.amount}
                            onChange={(e) => setNewExercise({...newExercise, amount: e.target.value})}
                        />
                    </WorkoutInputContent>
                    <WorkoutInputContent width={'5em'} margin={'0 10px 0.5em 10px'} >
                        {dropdown(options, 'options')}
                    </WorkoutInputContent>
                </div>
            </div>
            <WorkoutInputContent direction={'row'} align={'flex-end'}>
            <WorkoutInputContent>
            <LABEL>{WordTranslations.weight[lang]}</LABEL>
            <StyledCustomInput
                name="weight"
                type="number"
                value={newExercise?.weight}
                onChange={(e) => setNewExercise({...newExercise, weight: e.target.value})}
            />
            </WorkoutInputContent>
            <WorkoutInputContent width={'5em'} margin={'0 10px 1em 10px'} >
                {dropdown(unit, 'unit')}
            </WorkoutInputContent>
            </WorkoutInputContent>
            <WorkoutInputContent direction={'row'}>
                <WorkoutInputContent>
                    <LABEL>{WordTranslations.description[lang]}</LABEL>
                    <StyledTextArea
                        name="description"
                        type="text"
                        value={newExercise?.description}
                        onChange={(e) => setNewExercise({...newExercise, description: e.target.value})}
                        width={'80%'}
                    />
                </WorkoutInputContent>
                <WorkoutInputContent align={'flex-end'} width={'fit-content'} height={{}}>
                    <Dropzone
                        width={'120px'}
                        height={'120px'}
                        theme={theme}
                        placeholder={NewWorkoutTranslations.upload_image[lang]}
                        content={newExercise}
                        setContent={setNewExercise}
                        accept={{
                            'image/*': ['.jpeg', '.jpg', '.png', '.gif']
                            // 'video/*': ['.mp4', '.mov', '.avi']
                        }}
                    />
                </WorkoutInputContent>
            </WorkoutInputContent>
            <SubmitButton label={WordTranslations.add[lang]} disabled={disabled}/>
        </WorkoutInputContainer>
        </form>
        </>
    )
}

const FinalSection = ({exerciseList, setExerciseList, disabled, lang}) => {
    const navigate = useNavigate();
    const {personalData, setPersonalData} = useData();
    const [value, setValue] = useState('')

    const navTo = () => {
        navigate(`/workout/my`);
    }

    const createObj = (name, objList) =>{
        const newObj = {
            [name]: {}
        }

        objList.forEach((obj) => {
            const key = Object.keys(obj)[0];
            newObj[name][key] = obj[key]; // Luo uuden objektin, joka kopioidaan vanhasta objektista
        });

        return newObj;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataObj = createObj(value, exerciseList);
        const formData = new FormData();
        formData.append('data', JSON.stringify(dataObj)); // Lisätään muu data

        // Lisää kaikki kuvat formData:an
        exerciseList.forEach((values, index) => {
            Object.values(values)[0].map((exercise, index) => {
                if (exercise.file) {
                    formData.append(`media_${index}`,exercise.file);
                  }
            })
          });

        await InsertNewWorkoutToDb(formData, navTo, setPersonalData, personalData)
    }

    return(
        <form onSubmit={handleSubmit} style={{display:'flex', justifyContent:'center', alignItems: 'flex-start'}}>
        <WorkoutInputContainer>
            <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                <LABEL>{NewWorkoutTranslations.name_of_workout[lang]}</LABEL>
                    <StyledWorkoutInput
                        type="text"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
            </div>

                <div style={{height:'60vh', width: '100%'}}>
                    <ScrollView items={exerciseList} renderItem={(item) => <PreviewRow exercise={item} workout={exerciseList} setExerciseList={setExerciseList} lang={lang}/>} />
                 </div>
                <SubmitButton disabled={value.length > 3 ? disabled : true} label={WordTranslations.done[lang]}/>
        </WorkoutInputContainer>
        </form>
    )
}

const NewWorkout = ({theme}) => {
    const navigate = useNavigate();
    const {language} = useLanguage();

    const [partList, setPartList] = useState([
        // {value:'', label:'Add name'},
        // {value:'legs', label:'Legs'},
        // {value:'full body', label:'Full body'},
        // {value:'chest', label:'Chest'}
    ])
    const [selectedItem, setSelectedItem] = useState(partList[0]?.value);
    const [newExercise, setNewExercise] = useState({name_of_exercise:"",amount:"",times:"",weight:"",description:"", unit:"kg", options: "rep", file:null});
    const [newVideo, setNewVideo] = useState({name: '', description: ''})
    const [newTrainingPlan, setNewTrainingPlan] = useState({name:'', description:'', days:[], file: null});
    const [metadata, setMetadata] = useState(null);
    const [exerciseList, setExerciseList] = useState([]);
    const [media, setMedia] = useState([]);
    const [disabled, setDisabled] = useState({add: true, done: true, previous: false, next: true});
    const [isFinalVisible, setIsFinalVisible] = useState(false);
    const [creationMode, setCreationMode] = useState(null);
    const [preview, setPreview] = useState(false);
    const [content, setContent] = useState("");
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    // {jalat: [{nameOfExercise:"Kyykky",amount:"10",times:"3",weight:"60",description:"dexc"}]}
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = WordTranslations?.supported[lang] ? WordTranslations?.supported[lang] : WordTranslations?.supported['en-EN']

    useEffect(() => {
        if(creationMode === null){
            clearStates()
            setDisabled({add: true, done: true, previous: false, next: true})
        }
    },[creationMode])

    useEffect(() => {
        if(creationMode === 'exercise'){
            setDisabled({...disabled, add: !TextMinLenCount(newExercise.name_of_exercise, 4)});
        }else{
            setDisabled({...disabled, add: !(TextMinLenCount(newExercise.name_of_exercise, 4) && TextMinLenCount(selectedItem, 2))})
        }
    },[newExercise.name_of_exercise, selectedItem])

    useEffect(() => {
        if(creationMode === 'video'){
            setDisabled({...disabled, next: !(TextMinLenCount(newVideo.name, 4) && TextMinLenCount(newVideo.description, 50) && newVideo?.file)});
        }
    },[newVideo])

    useEffect(() => {
        if(creationMode === 'training_plan'){
            setDisabled({...disabled, next: !(newTrainingPlan?.days?.length > 0)});
        }
    }, [newTrainingPlan])


    useEffect(() => {
        if(exerciseList.length > 0){
            if(!isFinalVisible){
                setDisabled({...disabled, next: false, done: false})
            }
            // setDisabled({...disabled, done: false})
        }else{
            setDisabled({...disabled, next: true, done: true})
        }
    },[exerciseList.length])


    const navTo = () => {
        navigate(`/workout/my`);
    }

    const clearStates = () => {
        setNewExercise({name_of_exercise:"",amount:"",times:"",weight:"",description:"", unit: "kg", options: "rep", file: null})
        setNewVideo({name: '', description: ''})
        setNewTrainingPlan({name: '', description: '', days:[], file: null})
    }

    const findObj = (list, key) => {
        // Boolean(list.find((name) => Object.keys(name)[0] === key))
        return list.find((name) => Object.keys(name)[0] === key)
    }

    const createNewObj = (objName, value) => {
        const newObj = {
            [objName]:[
                value
            ]
        }
        return newObj;
    }

    const handlePushToList = (e) => {

        e.preventDefault();
        if(findObj(exerciseList, selectedItem)){
            const obj = Object.values(findObj(exerciseList, selectedItem))[0]
            obj.push(newExercise)
        }else{
            exerciseList.push(createNewObj(selectedItem, newExercise));
        }

        clearStates();
    }

    const handleSingleItem = (e) => {
        e.preventDefault();
        exerciseList.push(newExercise);
        clearStates();
    }

    const handleNext = async () => {
        if(creationMode === 'workout' && (!isFinalVisible)){
            setIsFinalVisible(!isFinalVisible);
            setDisabled({...disabled, previous: false, next: false})
        }else if(creationMode === 'exercise'){
            handlePreviewItems();
            setDisabled({...disabled, previous: false, next: false})
        }else if(creationMode === 'video'){
            const formData = new FormData();
            formData.append('data', JSON.stringify(newVideo)); // Lisätään muu data
            formData.append(newVideo?.name + '-video',newVideo?.file);

            await InsertNewVideo(formData);
            // setCreationMode(null)
            // setDisabled({...disabled, previous: false, next: false})
        }else if(creationMode === 'training_plan'){
            ConfirmationPopUp(newTrainingPlan);
        }
    }

    const ConfirmationPopUp = (data) => {
        const buttons = [
          // { label: 'Ei', onClick: handleCancel, color: Colors.red},
          { label: `${WordTranslations.save[lang]}`, onClick:() =>  handleTrainingPlanSubmit(data)},
          { label: `${WordTranslations.cancel[lang]}`, onClick: () => setIsPopUpOpen(false)}
        ];
  
        const message =`${NewWorkoutTranslations.confirm_message[lang].split(":")[0]} ${data?.days.length}${NewWorkoutTranslations.confirm_message[lang].split(":")[1]} "${data?.name}" ${NewWorkoutTranslations.confirm_message[lang].split(":")[2]} \n`

        setContent({message: message, buttons: buttons})
        setIsPopUpOpen(!isPopUpOpen);
      }

    const handlePrevious = () => {
        if(isFinalVisible){
            setIsFinalVisible(!isFinalVisible);
            setDisabled({...disabled, previous: false, next: false})
        }else if(creationMode === 'exercise' && preview){
            handlePreviewItems();
            setDisabled({...disabled, previous: false, next: false})
        }else{
            setCreationMode(null);
        }
    }

    const handlePreviewItems = () => {
        setPreview(!preview);

        // setCreationMode('exercise');
    }

    const handleRadioChange = (selectedValue) => {
        //pidä tieto ylhäällä.
        setCreationMode(selectedValue)
      };

      const handleExerciseSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('data', JSON.stringify(exerciseList)); // Lisätään muu data

        // Lisää kaikki kuvat formData:an
        exerciseList.map((value, index) => {

            if (value.file) {
                 formData.append(value.name_of_exercise,value.file);
            }
          });
        await InsertNewExercise(formData)
    }

    const handleTrainingPlanSubmit = async (data) => {
      
        try {
            data?.days.forEach((item, index) => {
                const idnum = Math.random() * 100;
                if(item.exercises.length > 0){
                    item.exercises.forEach((value, i) => {
                        value.part_of_workout_id = "plan-" + Math.floor(idnum) + String(index) + String(new Date().getMilliseconds());
                    })
                }
    
            });
            
            const formData = new FormData();
            formData.append('data', JSON.stringify(data)); // Lisätään muu data
    
            if (data.file) {
                 formData.append(data.name,data.file);
            }
            
            await InsertNewTrainingplan(formData, navTo);
        } catch (error) {
            console.error(error)
        }
    }

    const Section = (key) => {

        switch (key) {
            case 'training_plan':
                return(
                    <TrainingPlanSection
                        theme={theme}
                        newTrainingPlan={newTrainingPlan}
                        setNewTrainingPlan={setNewTrainingPlan}
                        metadata={metadata}
                        setMetaData={setMetadata}
                        lang={lang}/>
                )

            case 'video':
                return(
                    <Videosection
                        newVideo={newVideo}
                        setNewVideo={setNewVideo}
                        theme={theme}
                        creationMode={creationMode}
                        setMetadata={setMetadata}
                        metadata={metadata}
                        lang={lang}/>
            )

            case 'exercise':
                case 'workout':
                return(
                    <>
                        { !preview ?
                            <ExerciseSection
                                theme={theme}
                                disabled={disabled.add}
                                partList={partList}
                                setPartList={setPartList}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                handlePushToList={handlePushToList}
                                newExercise={newExercise}
                                setNewExercise={setNewExercise}
                                media={media}
                                setMedia={setMedia}
                                handleRadioChange={handleRadioChange}
                                creationMode={creationMode}
                                handleSingleItem={handleSingleItem}
                                setMetaData={setMetadata}
                                metadata={metadata}
                                lang={lang}/>
                        :
                            <ExerciseListPreview
                                exerciseList={exerciseList}
                                setExerciseList={setExerciseList}
                                setPreview={setPreview}
                                lang={lang}
                            />
                        }
                    </>
                )


            default:
                <p>Something went wrong!</p>
                break;
        }
    }

    return(
        <Container direction={'column'} justify={'space-between'} height={'fit-content'} padding={'25px 0px 0px 0px'} style={{overflow: 'clip'}}>
            {!creationMode ?
                <NewWorkoutSelection  setCreationMode={setCreationMode} language={language}/>
            :
            <>
            {!isFinalVisible ?
            <>
                {Section(creationMode)}
            </>
            :
            <FinalSection
                exerciseList={exerciseList}
                setExerciseList={setExerciseList}
                disabled={disabled.done}
                media={media}
                setMedia={setMedia}
                lang={lang}/>
            }
            <ButtonContainer>
                <CustomButton disabled={disabled.previous} label={'<'} onClick={() => handlePrevious()} button={StyledSubmitButton} style={{margin: '5px'}}/>
                {preview ?
                    <CustomButton label={WordTranslations.done[lang]} onClick={handleExerciseSubmit} button={StyledSubmitButton} style={{margin: '5px'}}/>
                    :
                    <CustomButton disabled={disabled.next} label={creationMode !== 'video' ? '>' : WordTranslations.done[lang]} onClick={() => handleNext()} button={StyledSubmitButton} style={{margin: '5px'}}/>
                }
            </ButtonContainer>
            </>
            }
            <CustomPopUp content={content} isOpen ={isPopUpOpen} setIsOpen={() => setIsPopUpOpen(!isPopUpOpen)} div={DescriptionContainer}/>
        </Container>

    )
}

export default NewWorkout;