import React, {useEffect, useState, useRef} from "react";
import { useNavigate } from 'react-router-dom';

//API
import { Notify } from "../Utils/ApiRequests.jsx";

// components
import Footer from "../components/Footer.jsx";
import HeaderText from "./HeaderText.jsx";
import MultiTextSection from "../components/MultiTextSection.jsx";
import CookieBanner from "../components/CookieBanner.jsx";
//Style
import { HomeContentContainer, MainContentContainer, TextContentContainer, TextContainer, ColumnContainer, FadeInContainer } from "../components/style/Container.styled.jsx";
import { Row, Column } from "../components/style/Row.styled.jsx";
import { MainContainer, MainSection, TopSquare, TopTriangle } from "../components/style/Section.styled.jsx";
import { MainDescriptionP } from "../components/style/paragraph.styled.jsx";
//image
import gym from '../components/style/images/gym.jpg'
//utils
import { GetPublicData } from "../Utils/ApiRequests.jsx";
import { useData, useLanguage, useWebSocket } from "../Utils/UseContext.jsx";
//Translations
import MainTranslations from './Translations/Main.json'




export const ContentContainer = ({title, content, width, justify, align, bg, titleColor, nav, Style}) => {

    return(
        <MainContentContainer bg={bg} width={width} justify={justify} align={align}>
            <Column>
                <Row padding={'none'} color={titleColor} onClick={nav}>
                    <h3 style={Style}>{title}</h3>
                </Row>
                {content}
            </Column>
        </MainContentContainer>
    )
}


const MainContent = ( {theme, language}) => {
    const navigate = useNavigate();
    let lang = language;
    // if language is not supported change to default (en-EN)
    lang = MainTranslations?.supported[lang] ? MainTranslations?.supported[lang] : MainTranslations?.supported['en-EN']
    let content; 

    const contentList = [
        // 1.section
        <Row>
            <MainDescriptionP theme={theme}>{MainTranslations.section1[lang]}</MainDescriptionP>
        </Row>,
        // 2.section
        <Row justify={'end'}>
            <MainDescriptionP theme={theme}><span className="gradient-text">{MainTranslations.section2[lang].split(":")[0]}:</span>{MainTranslations.section2[lang].split(":")[1]}</MainDescriptionP>
        </Row>,
        // 3.section
        <Row>
            <MainDescriptionP theme={theme}><span className="underline-text gradient-text" onClick={() => navigate('/workout/public')} style={{cursor:'pointer', textDecoration: 'underline'}}>{MainTranslations.section3[lang].split(":")[0]}:</span>{MainTranslations.section3[lang].split(":")[1]}</MainDescriptionP>
        </Row>,
        // 4.section
        <Row justify={'end'}>
            <MainDescriptionP theme={theme}>
                <span className="gradient-text">{MainTranslations.section4[lang].split(":")[0]}:</span>{MainTranslations.section4[lang].split(":")[1]}
            </MainDescriptionP>
        </Row>,
        // 5.section
        <Row>
            <MainDescriptionP theme={theme}>
                <span className="gradient-text">{MainTranslations.section5[lang].split(":")[0]}:</span>{MainTranslations.section5[lang].split(":")[1]}
            </MainDescriptionP>
        </Row>,  
        // 6.section
        <Row justify={'end'}>
            <MainDescriptionP theme={theme}>
            {MainTranslations.section6[lang].split(":")[0]} <span onClick={() => navigate('/registeration')} style={{cursor:'pointer', textDecoration: 'underline', textTransform: 'uppercase'}}>{MainTranslations.section6[lang].split(":")[1]}</span> {MainTranslations.section6[lang].split(":")[2]}
            </MainDescriptionP>
        </Row>  
        
    ]


         content = 
            <MainContentContainer justify={'space-between'} >
                    <MultiTextSection texts={contentList} />
            </MainContentContainer>
        
    
    return content;
}

const Main = ({onInstallClick, theme}) => {
    const {language} = useLanguage()
    const {publicData, setPublicData} = useData();

    const ws = useWebSocket();

    useEffect(() => {
      if (ws) {
        GetPublicData({setPublicData, ws});
      }
    }, [ws]);

    return(
        <>
            <CookieBanner />
            <MainSection >
                <Row margin={'30px auto'} justify_mobile={'center'} margin_mobile={'30px auto'}>
                    <HeaderText theme={theme} language={language}/>
                </Row>
                <TopSquare image={gym}/>
                <MainContent theme={theme} data={publicData} language={language}/>
            </MainSection>
            <Footer language={language} onInstallClick={onInstallClick}/>
        </>
    )
}

export default Main